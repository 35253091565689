<ng-container>
  <div class="image-manager">
    <div class="loading" *ngIf="fileService.loading$ | async; else ready">
      <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
    </div>
    <ng-template #ready>
      <div class="choosenImageContainer" *ngIf="mainImage && mainImage.image; else noImage">
        <img (click)="enhanceImage()" class="choosen-image" [src]="mainImage.image" alt="" srcset="">
      </div>
      <ng-template #noImage>
        <div class="noimage">
          <h5>{{statusMessage}}</h5>
        </div>
      </ng-template>
      <div *ngIf="deviceImages && deviceImages.length > 0">
        <div class="slider-wrapper">
          <ng-image-slider [imagePopup]="false" [arrowKeyMove]="false"
            [imageSize]="{width: '64px', height: '48px', space: 1}" [images]="deviceImages" #nav
            (imageClick)="changeMainImage($event)" [animationSpeed]="0.1" [showArrow]="false">
          </ng-image-slider>
        </div>

        <div class="image-counter">
          <span class="counter-button" [ngClass]="currentIndex > 0 ? 'show' : 'hide' " (click)="prevImageClick()">
            <mat-icon>arrow_back</mat-icon>
          </span>
          {{ deviceImages.length > 0 ? currentIndex + 1 : 0}} of {{ deviceImages.length }}
          <span class="counter-button" [ngClass]="currentIndex < (deviceImages.length - 1) ? 'show' : 'hide'"
            (click)="nextImageClick()">
            <mat-icon>arrow_forward</mat-icon>
          </span>
        </div>

      </div>
    </ng-template>

  </div>
  <div class="image-actions" *ngIf="shouldDisplay()">
    <app-file-manager documentType="image" [file]="mainImage" (addItem)="addItem($event)"
      (removeItem)="removeItem($event)" [objectId]="entityId"></app-file-manager>
  </div>

</ng-container>
<ng-template #bigImage *ngIf="mainImage && mainImage.image">
  <div class="wrapper-wrapper">
    <div class="close">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="enchancedImage">
      <div class="enchancedImage-wrapper">
        <img class="fullsize" [src]="mainImage.image" alt="" srcset=""> <!--Use preloaded image here-->
      </div>
    </div>
  </div>
</ng-template>
