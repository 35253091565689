<div class="comments" [style]="'height:' + height + 'vh'">
  <div class="comments-content">
    <app-comment *ngFor="let comment of comments$ | async" [comment]="comment" [section]="section"
      (delete)="deleteComment(comment)"></app-comment>
  </div>
  <div class="comment-input w-100" [formGroup]="commentForm" *ngIf="shouldDisplay()">
    <mat-form-field appearance="outline" class="w-100">
      <input (keyup.enter)="createComment()" formControlName="comment" matInput type="text" class="w-100"
        [placeholder]="'common.media-manager.comments.comment_placeholder' | translate">
    </mat-form-field>
    <div class="comment-send">
      <button (click)="createComment()" mat-flat-button color="primary"
        [disabled]="commentForm.get('comment')?.value === ''">
        <span><mat-icon>send</mat-icon></span>
      </button>
    </div>
  </div>
</div>
