import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMapTo, take, shareReplay } from 'rxjs/operators';
import { ID, Model } from 'src/app/core/interfaces/model.interface';
import { NotificationService } from '../services/notification.service';
import { DataService, DataServiceIdRequestOptions, DataServiceRequestOptions } from './data.service';

export abstract class CachedDataService<Item extends Model> extends DataService {

  // array of items from GET /resources
  protected items: Record<ID, Item> = {}; // TODO: use memcache, indexed db, local storage or something
  protected readonly $items = new ReplaySubject<Item[]>(1);
  public readonly items$ = this.$items.pipe(shareReplay(1));

  // individual items from GET /resources/:id
  private $item: Record<ID, ReplaySubject<Item>> = {};
  private readonly item$: Record<ID, Observable<Item>> = {};

  constructor(
    protected override http: HttpClient,
    protected override notificiationService: NotificationService,
    protected override resource: string,
    private deserialize: (data: Item, index: ID) => Item
  ) {
    super(http, notificiationService, resource);
  }

  // helpers
  public cachedPeek(options: DataServiceIdRequestOptions): Observable<Item> {
    const { id } = options;
    return this.items$.pipe(
      take(1),
      switchMapTo(this.item$[id] || this.cachedGet(options)),
      shareReplay(1),
    );
  }

  // for services were we get static data, we can just open existing data without fetching it again.
  public cachedOpen(options: DataServiceIdRequestOptions): Observable<Item> {
    const { id } = options;
    if (this.items[id]) {
      this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
      this.item$[id] = this.item$[id] || this.$item[id].pipe(shareReplay(1));
      this.$item[id].next(this.items[id]);
      return this.item$[id];
    }
    return this.cachedGet(options);
  }


  public cachedSyncPeek(options: DataServiceIdRequestOptions): Observable<Item> {
    const { id } = options;
    return this.items$.pipe(
      take(1),
      map(() => this.items[id]), // TODO: mapTo doesn't work..?
    );
  }

  // CRUD
  protected cachedGetAll(options: DataServiceRequestOptions = {}) {

    super.requestGetAll<Item>(options)
      .pipe(map(items => items.map(this.deserialize)))
      .subscribe(
        (data: Item[]) => {
          this.items = data.reduce((record: Record<ID, Item>, item: Item) => {
            record[item.id] = item;
            return record;
          }, {});
          this._closeUnusued();
          this.$items.next(data);
        }
      );

    return this.items$;
  }

  protected cachedGet(options: DataServiceIdRequestOptions) {

    const { id } = options;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].pipe(shareReplay(1));
    // optimistically start with the item from the main array
    const item = this.items[id];
    if (item) {
      this.$item[id].next(this._unreference(item));
    }

    super.requestGet<Item>(options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        if (this._different(item, data)) {
          if (this.$item[id]) {
            this.$item[id].next(this._unreference(data));
          }
          this.items[id] = this._unreference(data);
          this.$items.next(Object.values(this.items));
        }
      });

    return this.item$[id];
  }

  protected cachedCreate(item: Item, options: DataServiceRequestOptions = {}) {

    const subject = new ReplaySubject<Item>(1);
    const observable = subject.pipe(distinctUntilChanged());

    // optimistically add it
    const optimisticItem = this.deserialize(item, item.id);
    this.$items.next([optimisticItem, ...Object.values(this.items)]);
    subject.next(optimisticItem);

    super.requestCreate<Item>(item, options)
      .pipe(map(this.deserialize))
      .subscribe(() => {
        // This section requiers modification if the backend only returns an ID instead of a complete object.
        // See comments and examples in cached.sparse.data.service:cachedCreate

        // Ramboll portal specific (201 no data)
        const id = optimisticItem.id;
        this.items[id] = optimisticItem;

        this.$item[id] = subject;
        this.item$[id] = observable;

        subject.next(optimisticItem);
      });
    return observable;
  }

  protected cachedUpdate(item: Item, options: DataServiceRequestOptions = {}) {

    const id = item.id;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].pipe(distinctUntilChanged());

    // optimistically patch item
    const backup = this._unreference(this.items[id]);
    const optimisticItem = Object.assign({}, this.items[id], item);
    if (this.$item[id]) {
      this.$item[id].next(this._unreference(optimisticItem));
    }
    this.items[id] = optimisticItem;
    this.$items.next(Object.values(this.items));

    super.requestUpdate<Item>(item, { id, ...options })
      .subscribe((data: Item) => {
        if (this._different(this.items[id], data)) {
          if (this.$item[id]) {
            this.$item[id].next(data);
          }
          this.items[id] = data;
          this.$items.next(Object.values(this.items));
        }
      },
      // tslint:disable-next-line: no-identical-functions
      () => {
        // restore optimistically changed item if operation fails
        if (this.$item[id]) {
          this.$item[id].next(this._unreference(backup));
        }
        this.items[id] = backup;
        this.$items.next(Object.values(this.items));
      });

    return this.item$[id];
  }

  protected cachedPatch(changes: Partial<Item>, options: DataServiceIdRequestOptions) {

    const { id } = options;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].pipe(distinctUntilChanged());

    // optimistically patch item
    const backup = this._unreference(this.items[id]);
    const optimisticItem = Object.assign({}, this.items[id], changes);
    if (this.$item[id]) {
      this.$item[id].next(this._unreference(optimisticItem));
    }
    this.items[id] = optimisticItem;
    this.$items.next(Object.values(this.items));

    super.requestPatch<Item>(changes, options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        if (this._different(this.items[id], data)) {
          if (this.$item[id]) {
            this.$item[id].next(this._unreference(data));
          }
          this.items[id] = this._unreference(data);
          this.$items.next(Object.values(this.items));
        }
      },
      // tslint:disable-next-line: no-identical-functions
      () => {
        // restore optimistically changed item if operation fails
        if (this.$item[id]) {
          this.$item[id].next(this._unreference(backup));
        }
        this.items[id] = backup;
        this.$items.next(Object.values(this.items));
      });

    return this.item$[id];
  }

  protected cachedDelete(options: DataServiceIdRequestOptions) {

    const { id } = options;

    // optimistically delete item
    const backup = this.items[id];
    delete this.items[id];
    this.$items.next(Object.values(this.items));

    super.requestDelete<Item>(options)
      .subscribe(() => {
        if (this.$item[id]) {
          if (this.$item[id]) {
            this.$item[id].complete();
          }
          delete this.$item[id];
        }
        delete this.items[id];
        this.$items.next(Object.values(this.items));
      },
      () => {
        // restore optimistically changed item if operation fails
        this.items[id] = backup;
        this.$items.next(Object.values(this.items));
      });
    return this.item$[id];
  }

  protected _close(id: ID) {
    if (this.$item[id]) {
      this.$item[id].complete();
      delete this.$item[id];
    }
  }

  private _closeUnusued() {
    // look at each cached Observer and check if it still exists. complete and remove it otherwise.
    this.$item = Object.entries(this.$item).reduce((record: Record<ID, ReplaySubject<Item>>, [id, $data]) => {
      if (this.items[id]) {
        $data.next(this.items[id]);
        record[id] = $data;
      } else {
        $data.complete();
        delete this.item$[id];
      }
      return record;
    }, {});
  }

  private _unreference(data: Item): Item {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch (e) {
      return Object.assign({}, data);
    }
  }

  private _different(a: Partial<Item>, b: Partial<Item>) {
    return !this._identical(a, b);
  }

  private _identical(a: Partial<Item>, b: Partial<Item>) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

}
