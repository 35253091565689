import { Component, EventEmitter, Input, Output, inject } from '@angular/core'
import { map } from 'rxjs'
import { LoadingService } from 'src/app/core'
import { UserService } from 'src/app/shared/services/user.service'
import { Comment } from 'src/app/shared/types/comment'

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input() comment!: Comment
  @Input() section?: string
  @Output() delete = new EventEmitter<boolean>()

  private msalUserService = inject(UserService)
  isClient$ = this.msalUserService.isClient$

  constructor(public loadingService: LoadingService) { }

  hideOnClient() {
    return this.isClient$.pipe(map(isClient => isClient && this.section === 'projects'))
  }

  deleteComment() {
    this.delete.emit(true)
  }
}
