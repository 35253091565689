import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { UsersService } from 'src/app/users/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class EulaGuard implements CanActivate {

  constructor(private userService: UserService, private appUsers: UsersService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.userService.currentUser$.pipe(
      map((user) => {
        if (user?.approvedEula) {
          return true;
        }
        return this.router.createUrlTree(['/login/eula']);
      })
    )
  }
}
