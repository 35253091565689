<div class="comment">
  <div class="comment-head">
    <img *ngIf="comment.metadata!['iconUri']; else profileImage" [src]="comment.metadata!['iconUri']"
      [alt]="comment.metadata!['name']" alt="Avatar" class="comment-profile" />
    <ng-template #profileImage>
      <mat-icon class="icon">person</mat-icon>
    </ng-template>
    <div class="comment-name">{{comment.metadata!['name']}}</div>
    <div class="comment-time">{{comment.timestamp | date: 'YYYY-MM-dd HH:mm'}}</div>
    <div *ngIf="!(loadingService.loading$ | async) && !(hideOnClient() | async) && !comment.metadata?.['preventDelete']"
      class="comment-close"><mat-icon (click)="deleteComment()">close</mat-icon></div>
  </div>
  <div class="comment-body">
    <div class="comment-text">
      <span>
        <b
          *ngIf="this.comment.message === 'completed' || this.comment.message === 'planned' || this.comment.message === 'created'">Status:
        </b>{{
        this.comment.message }}</span>
      <span *ngIf="comment.reported"> - <b>Task: </b>{{ comment.reported }}</span>
      <span *ngIf="comment.metadata?.['comment']"> - <b>Comment: </b>{{ comment.metadata?.['comment'] }}</span>
    </div>
    <div class="comment-separator w-100"></div>
  </div>
</div>