import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { DataServiceRequestOptions, PaginatedResponse } from 'src/app/core/classes/data.service.v2';
import { Event } from '@ramboll/rsedt-shared';
@Injectable({
  providedIn: 'root'
})
export class EventService<Event> extends CachedDataServiceV2<Event> {

  constructor(
    protected override http: HttpClient,
  ) {
    super(
      http,
      'event'
    );
  }

  search(options?: DataServiceRequestOptions): Observable<PaginatedResponse<Event>> {
    return this.cachedSearch({ resource: 'event', endpoint: 'event/search', params: options?.params, apiVersion: 2 });
  }

  create(item: EventCreateData, options?: DataServiceRequestOptions) {
    return super.cachedCreate(item as Event, { resource: 'event', params: options?.params, apiVersion: 2, endpoint: 'event' });
  }

  delete(options?: DataServiceRequestOptions) {
    return super.cachedDelete({ id: options?.id, apiVersion: 2, endpoint: 'event' })
  }

  getLatestDeviceInstallation(options?: DataServiceRequestOptions): Observable<Event[]> {
    return super.cachedGetArray({ id: options?.id as string, resource: 'event', endpoint: `event/device/${options?.id}/install/latest`, addId: false })
  }
}

export type EventCreateData = Omit<Event, 'id'>;
