import { Component, OnInit, Input, inject } from '@angular/core';
import { Observable, combineLatest, map, take, zip, Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { LoadingService } from 'src/app/core';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CommonDialogConfig } from 'src/app/shared/types/common-dialog.interface';
import { MatDialog } from '@angular/material/dialog';
import { Comment } from 'src/app/shared/types/comment';
import { CommentService } from 'src/app/shared/services/comment.service';
import { FileTypeEntities, MediaManagerEntities } from '../media-manager.component';
import { DataServiceRequestOptions } from 'src/app/core/classes/data.service.v2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocationsService2 } from 'src/app/locations/services/locations.service2';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  commentForm!: FormGroup;
  @Input() entityType!: FileTypeEntities
  @Input() entityId!: string
  @Input() subEntity?: string
  @Input() height = 60
  @Input() section?: string

  private msalUserService = inject(UserService)
  public isClient: boolean = false;

  protected comments$!: Observable<Comment[]>;
  subscriptions = new Map<string, Subscription>();

  constructor(
    private userService: UserService,
    private commentService: CommentService,
    private locationsService: LocationsService2,
    public loadingService: LoadingService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    if (this.subEntity === 'information') {
      this.comments$ = combineLatest([
        this.commentService.comments$,
        this.locationsService.active$,
      ])
        .pipe(map(([comments, location]) => {
          if (location) {
            comments.push({
              id: 'n/a',
              timestamp: location.createdAt as Date,
              category: 'COMMENT',
              name: 'Information point instruction',
              metadata: {
                name: 'Information point instruction',
                preventDelete: true,
              },
              message: location.description,
              relatedObjectIds: {
                locationId: location.locationId
              }
            } as Comment)
          }
          return comments.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);
        }))
    } else {
      this.comments$ = this.commentService.comments$;
    }

    this.subscriptions.set(
      'isClient',
      this.msalUserService.isClient$.subscribe(isClient => this.isClient = isClient)
    );

    this.commentForm = this.fb.group({
      comment: ['']
    });
    this.commentService.getComments({ params: this.generateParams() }, true)
  }

  createComment() {
    if (!this.commentForm.get('comment')?.value || this.commentForm.get('comment')?.value === '') {
      return
    }

    zip([
      this.userService.currentUser$,
      this.userService.accountIconUrl$
    ]).pipe(take(1)).subscribe({
      next: ([user, iconUri]) => {
        const comment = {
          name: user?.userName,
          category: 'COMMENT',
          message: this.commentForm.get('comment')?.value,
          timestamp: new Date(),
          metadata: {
            name: user?.userName,
            email: user?.mail,
            iconUri: iconUri
          },
          relatedObjectIds: this.generateEntityObject()
        };

        this.commentService.create(comment, { params: this.generateParams() }).pipe(take(1)).subscribe({
          error: err => {
            console.error(err)
            this.snackbarService.customError(`${this.translate.instant('common.media-manager.comments.comment_error')}`)
          },
          complete: () => {
            this.commentForm.get('comment')?.setValue('')
          }
        })
      }
    })
  }

  deleteComment(comment: Comment) {
    const dialogData: CommonDialogConfig = {
      title: 'common.media-manager.comments.delete_comment',
      message: 'common.media-manager.comments.delete_comment_message',
      buttons: [{
        text: 'common.cancel',
        value: false,
        color: 'basic'
      }, {
        text: 'common.delete',
        value: true,
        color: 'warn'
      }],
      styles: {
        maxWidth: '330px'
      }
    }

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: dialogData
    })

    dialogRef.afterClosed().subscribe({
      next: (dialogResult: boolean) => {
        if (dialogResult === true) {
          this.commentService.delete(comment, { params: this.generateParams() }).subscribe({
            complete: () => {
              this.snackbarService.customSuccessDark(`${this.translate.instant('common.media-manager.comments.comment_deleted')}`)
            }
          })
        }
      }
    })
  }

  generateParams(): DataServiceRequestOptions['params'] {
    const defaultParams = {
      take: 50,
      sortField: 'timestamp',
      sortOrder: 'desc'
    }

    const params = {
      ...defaultParams,
      ...this.generateEntityObject()
    }
    return params
  }

  generateEntityObject(): Record<string, string> {
    let entityObject = {}
    switch (this.entityType) {
      case 'fieldworkAreas':
        entityObject = { workAreaId: this.entityId }
        break;
      case 'locations':
        entityObject = { locationId: this.entityId }
        break;
      case 'devices':
        entityObject = { deviceId: this.entityId }
        break;
      case 'projects':
        entityObject = { projectId: this.entityId }
        break;
      case 'initiatives':
        entityObject = { initiativeId: this.entityId }
        break;

      default:
        entityObject = { locationId: this.entityId }
    }
    return entityObject
  }

  shouldDisplay(): boolean {
    if (this.entityType === MediaManagerEntities.PROJECTS) {
      return true;
    }

    return !this.isClient;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
