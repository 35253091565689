import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, ReplaySubject, shareReplay, startWith, switchMap } from 'rxjs';
import { CachedDataService } from 'src/app/core/classes/cached.data.service';
import { ID } from 'src/app/core/interfaces/model.interface';
import { NotificationService } from 'src/app/core/services/notification.service';
import { User } from 'src/app/shared/models/user';
import { AbstractUser } from 'src/app/shared/types/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends CachedDataService<User> {

  private readonly $active = new ReplaySubject<User>(1);
  public active$ = this.$active.pipe(shareReplay(1));
  private readonly $tabIndex = new ReplaySubject<number>(1);
  public tabIndex$ = this.$tabIndex.pipe(startWith(0), shareReplay(1));
  private readonly $allUsers = new BehaviorSubject<User[]>([]);
  public allUsers$ = this.$allUsers.pipe(shareReplay(1));

  constructor(
    protected override http: HttpClient,
    protected notificationService: NotificationService
  ) {
    super(
      http,
      notificationService,
      'users',
      (input: User) => new User(input).deserialize(input, input.userId),
    );
    // this.cachedGetAll(); -- This triggered the error.
  }

  getAll(): Observable<User[]> {
    return this.cachedGetAll().pipe(
      map(data => {
        this.updateUsers(data)
        return data
      })
    )
  }

  open(id: ID) {
    if (!id) {
      return;
    }
    this.active$ = this.items$.pipe(switchMap(() => this.cachedPeek({ id })));
    return this.active$;
  }

  setTabIndex(index: number) {
    this.$tabIndex.next(index);
  }

  save(user: User): Observable<User> {
    return this.cachedPatch(user, { id: user.userId as ID, skipRefresh: true })
  }

  create(user: AbstractUser): Observable<AbstractUser> {
    return this.cachedCreate(new User(user).deserialize(user, user.userId), {})
  }

  remove(id: string): Observable<User> {
    return this.cachedDelete({ id });
  }

  /**
   * Handle users data globally in app.
   * Use getUsers to data on get all users
   * Use Updateusers to update users
   */
  private set allUsers(val: User[]) {
    this.$allUsers.next(val)
  }

  private get allUsers(): User[] {
    return this.$allUsers.getValue()
  }

  updateUsers(val: User[]): void {
    this.allUsers = val
  }

  getUsers(): User[] {
    return this.allUsers
  }
}
