import { Component, EventEmitter, Input, Output, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';
import { DocumentFile } from 'src/app/shared/models/documentfile';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AbstractDocumentFile, FileType } from 'src/app/shared/types/documentfile';
import { MediaManagerEntities } from '../../media-manager.component';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent {
  @Input() file: DocumentFile | undefined // item to delete
  @Input() objectId!: string // ex locationid
  @Input() entityType!: MediaManagerEntities
  @Input() documentType!: FileType // ex image / pdf
  @Output() addItem: EventEmitter<any> = new EventEmitter(); // this is type any for the soul purpose of generalization
  @Output() removeItem: EventEmitter<any> = new EventEmitter(); // this is type any for the soul purpose of generalization
  @ViewChild ('deleteImage') deleteDialog!: TemplateRef<HTMLElement>;
  uploadLoading = false
  deleteLoading = false

  constructor(
    private filesService: FileService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) { }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement
    const file: File | undefined = target?.files?.[0];
    if (file) {
      this.uploadLoading = true
      const body: AbstractDocumentFile = {
        "objectId": this.objectId,
        "objectType": "location", // Logic in backend forced this to be 'location'
        "documentType": this.documentType,
      }
      this.filesService.create(body, file).pipe(take(1)).subscribe({
        next: (data) => {
          if (data) {
            this.addItem.emit(data);
            this.uploadLoading = false
            this.snackbarService.customSuccessDark(this.translate.instant('generic.image_uploaded'))
          }
        },
        error: () => {
          this.uploadLoading = false
          this.snackbarService.genericErrorCreate()
        }

      })
    } else {
      this.uploadLoading = false
      console.debug('No file selected')
    }
  }

  deleteFile(): void {
    if (this.file) {
      this.deleteLoading = true
      this.filesService.remove(this.file).pipe(take(1)).subscribe({
        next: (data) => {
          this.snackbarService.customSuccessDark(this.translate.instant('generic.image_deleted'))
          this.removeItem.emit(data)
          this.deleteLoading = false
          this.dialog.closeAll()
        },
        error: () => {
          this.deleteLoading = false
          this.snackbarService.genericErrorDelete()
        }
      })
    } else {
      this.deleteLoading = false
    }
  }

  close() {
    this.dialog.closeAll()
  }

  openDeleteDialog(): void {
    this.dialog.open(this.deleteDialog).afterClosed()
  }
}
