import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location, LocationObject } from '@ramboll/rsedt-shared';
import { forkJoin, Observable, of, ReplaySubject, shareReplay, tap } from 'rxjs';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { DataServiceRequestOptions } from 'src/app/core/classes/data.service.v2';


@Injectable({
  providedIn: 'root'
})
export class LocationObjectsService2 extends CachedDataServiceV2<LocationObject> {

  private readonly $active = new ReplaySubject<LocationObject>(1);
  public active$ = this.$active.pipe(shareReplay(1));

  // This is to be able to control in which order the form should be rendered.
  public readonly locationObjectPropertiesOrder = new Map<string, string[]>([
    ['BOREHOLE', ['casing_filter', 'material', 'well_diameter', 'casing_length', 'casing_filter_type', 'casing_filter_length', 'aquafer', 'z_level', 'status', 'sectioned', 'number_of_sections']]
  ]);

  constructor(protected override http: HttpClient) {
    super(
      http,
      'locationobject');
  }

  create(item: LocationObject, options?: DataServiceRequestOptions): Observable<LocationObject> {
    return this.cachedCreate(item, { resource: 'measurement', endpoint: 'locationobject', id: options?.id as string, apiVersion: 2 });
  }

  patch(item: LocationObject, options?: DataServiceRequestOptions): Observable<LocationObject> {
    return this.cachedPatch(item, { id: options?.id as string, resource: 'measurement', endpoint: 'locationobject', params: options?.params, apiVersion: 2 })
      .pipe(tap((res) => this.$active.next(res)));
  }

  removeAll(location: Location, type: string): Observable<LocationObject[]> {
    const objects = type === 'structure' ? location.objects?.[0].objects : location.objects

    if (objects?.length) {
      return forkJoin(objects.map(obj => this.cachedDelete({ resource: 'measurement', endpoint: 'locationobject', id: obj.objectId as string, apiVersion: 2 })))
    }
    return of([]);
  }
}
