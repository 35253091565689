import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  public static error(...args: any[]) {
    if (!environment.disableLogging) {
      console.error(...args);
    }
  }

  public static warn(...args: any[]) {
    if (!environment.disableLogging) {
      console.warn(...args);
    }
  }

  public static info(...args: any[]) {
    if (!environment.disableLogging) {
      console.log(...args);
    }
  }

  public static debug(...args: any[]) {
    this.info(...args);
  }

  public static trace(...args: any[]) {
    this.info(...args);
  }
}
