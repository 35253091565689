import { ID, Model } from 'src/app/core/interfaces/model.interface';
import { AbstractUser } from '../types/user';

export class User extends AbstractUser implements Model {
  id!: ID;
  deserialize(input: AbstractUser, id?: ID): this {
    return Object.assign(
      this,
      input,
      id ? { id } : {},
    );
  }
}