import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgImageSliderComponent } from 'ng-image-slider';
import { debounceTime, Subscription } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';
import { DocumentFile } from 'src/app/shared/models/documentfile';
import { Bild, Fastighet } from '@ramboll/rsedt-shared';
import { UserService } from 'src/app/shared/services/user.service';
import { FileTypeEntities, MediaManagerEntities } from '../media-manager.component';
export interface sliderImage extends DocumentFile {
  image?: string
  order?: number
  index?: string
  thumbImage?: string
  alt?: string
}
@Component({
  selector: 'app-device-details-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['../../../../devices/devices.shared.scss', './gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {
  @Input() entityId!: string
  @Input() entityType!: FileTypeEntities
  @Input() showActionButton = true
  @Input() raskData!: Fastighet
  @Input() section?: string
  @ViewChild('bigImage') bigImage!: TemplateRef<any>;
  @ViewChild('nav') slider!: NgImageSliderComponent;

  private msalUserService = inject(UserService)
  public isClient: boolean = false;

  mainImage: sliderImage | undefined
  deviceImages: sliderImage[] | undefined
  currentIndex = 0
  statusMessage: string | undefined
  subscriptions = new Map<string, Subscription>();
  constructor(
    private dialog: MatDialog,
    public fileService: FileService,
    private translateService: TranslateService
  ) { }

  prevImageClick() {
    if (this.currentIndex === 0) { return }
    const newIndex = this.currentIndex = (this.currentIndex - 1)
    this.handleOpacity(newIndex, 0)
    this.mainImage = this.deviceImages && this.deviceImages[newIndex]
    if (this.deviceImages && newIndex < this.deviceImages.length - 5) {
      this.slider.prev();
    }
  }

  nextImageClick() {
    if (this.deviceImages) {
      if (this.currentIndex + 1 === this.deviceImages.length) { return }
      const newIndex = this.currentIndex = (this.currentIndex + 1)
      this.handleOpacity(newIndex, 0)
      this.mainImage = this.deviceImages[newIndex]
      if (newIndex > 5) {  // Follow the "camera" on slider
        this.slider.next()
      }
    }
  }

  ngOnInit(): void {
    this.deviceImages = []
    this.statusMessage = this.translateService.instant('common.label_loading')
    this.subscriptions.set(
      'isClient',
      this.msalUserService.isClient$.subscribe(isClient => this.isClient = isClient)
    );

    if (this.raskData) {
      if (this.raskData.objbild || this.raskData.varselbild) {
        this.addPropsToImages([], this.raskData)
      } else {
        this.statusMessage = this.translateService.instant('common.picture-manager.no_image')
      }
    } else {
      this.subscriptions.set(
        'files',
        this.fileService.images$.pipe(
          debounceTime(500),
        )
          .subscribe(data => {
            if (data.length > 0) {
              this.addPropsToImages(data)
            } else {
              this.statusMessage = this.translateService.instant('common.picture-manager.no_image')
            }
          })
      )
    }
  }

  private includedImages(images: Bild[] | undefined) {
    if (images) {
      return images.map(image => ({
        image: image.path,
        thumbImage: image.path + '?thumbnail=true'
      })) as sliderImage[];
    } else {
      return []
    }
  }

  // Add props needed for ng-image-slider to work properly. (image, thumbnail)
  addPropsToImages(images: sliderImage[], Fastighet?: Fastighet): void {
    if (this.raskData) {
      const objectImage = this.includedImages(Fastighet?.objbild);
      const varselImage = this.includedImages(Fastighet?.varselbild);

      const sliderImage = [
        ...objectImage, ...varselImage
      ]
      this.deviceImages = sliderImage
      this.mainImage = this.deviceImages[0]
      this.handleOpacity(0, 500)
    } else {
      for (const [index, image] of images.entries()) {
        const path = "/api/v1/files/images/" + image.fileName
        image.image = path
        image.thumbImage = path + '?thumbnail=true'
        image.alt = this.translateService.instant('common.picture-manager.alt_text') + (index + 1)
      }
      this.deviceImages = images
      this.mainImage = this.deviceImages[0]
      this.handleOpacity(0, 500)
    }
  }

  // Changes the displayed image
  changeMainImage(index: number): void {
    if (this.mainImage && this.deviceImages) {
      this.mainImage = this.deviceImages[index]
      if (index > this.currentIndex && index > 5) {
        this.slider.next()
      } else if (index < this.currentIndex && this.deviceImages && index < this.deviceImages.length - 5) {
        this.slider.prev()
      }
      this.currentIndex = index
      this.handleOpacity(index, 0)
    }
  }

  // Set opacity on all elements, highlight index
  handleOpacity(index: number, timeout: number): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.img-div') as unknown as HTMLElement[]
      for (const el of elements) {
        if (el && el.style) {
          el.style.opacity = '0.2';
        }
        (document.querySelectorAll('.img-div')[index] as HTMLElement).style.opacity = '1';
      }
    }, timeout);
  }

  enhanceImage(): void {
    const dialogRef = this.dialog.open(this.bigImage, {
      width: '100vw',
      maxWidth: '100vw',
    });
    dialogRef.afterClosed().subscribe()
  }

  // Add item to images array. Add needed data & scroll slider to image.
  addItem(newImage: sliderImage) {
    let index = 0
    this.deviceImages && this.deviceImages.length > 0 ? index = this.deviceImages.length : index = 0

    const urlPath = "/api/v1/files/images/" + newImage.fileName
    newImage.image = urlPath
    newImage.thumbImage = urlPath + '?thumbnail=true'
    newImage.alt = this.translateService.instant('common.picture-manager.alt_text') + (index + 1)
    this.deviceImages ? this.deviceImages.push(newImage) : ''
    // Scroll slider to new image
    setTimeout(() => {
      if (this.deviceImages && this.deviceImages.length > 0) {
        this.mainImage = this.deviceImages[index]
        // Scroll slider to new added image
        this.slider.slideImageCount = index
        this.slider.autoSlideCount = index
        this.slider.autoSlide = index
        this.slider.imageAutoSlide()
        this.slider.next()

        // Reset slider config
        this.slider.slideImageCount = 1
        this.slider.autoSlideCount = 1
        this.slider.autoSlide = 1

        this.currentIndex = index
        this.handleOpacity(index, 0)
      }
    }, 500);
  }

  removeItem(imageToDelete: sliderImage) {
    if (this.deviceImages) {
      const newArray = this.deviceImages.filter((image) => image.fileName !== imageToDelete.fileName)
      this.deviceImages = newArray

      if (this.deviceImages.length <= 0) {
        this.translateService.instant('common.picture-manager.no_image')
        this.currentIndex = 0
        this.mainImage = this.deviceImages[0]
      } else {
        if (this.deviceImages[this.currentIndex + 1]) {
          this.currentIndex + 1
          this.mainImage = this.deviceImages[this.currentIndex]
          this.handleOpacity(this.currentIndex, 50)
        }
        else if (this.deviceImages[this.currentIndex - 1]) {
          this.currentIndex = this.currentIndex - 1
          this.mainImage = this.deviceImages[this.currentIndex]
          this.handleOpacity(this.currentIndex, 100)
        }
        else {
          this.currentIndex = 0
          this.mainImage = this.deviceImages[0]
          this.handleOpacity(0, 50)
        }
      }
    }
  }

  close() {
    this.dialog.closeAll()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  shouldDisplay(): boolean {
    if (!this.showActionButton) {
      return false;
    }

    if (this.entityType === MediaManagerEntities.PROJECTS) {
      return true;
    }

    return !this.isClient;
  }
}
