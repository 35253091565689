import { HttpClient } from "@angular/common/http";
import { Observable, of, tap } from "rxjs";
import { DataServiceRequestOptions, PaginatedResponse, DataServiceV2 } from "./data.service.v2";

export abstract class CachedDataServiceV2<T> extends DataServiceV2 {

  // How many requests should be in cache at most.
  private readonly CACHE_SIZE = 10;

  // Map of stringified request as key and response as value.
  private simpleCache = new Map<string, T | PaginatedResponse<T>>();

  constructor(
    protected override http: HttpClient,
    protected override resource: string,
  ) {
    super(http, resource);
  }

  public cachedGet(options: DataServiceRequestOptions) {
    const optionsStr = this.getOptionsStr(options);
    if (optionsStr) {
      const cache = this.simpleCache.get(optionsStr);
      if (cache) {
        // Return from cache.
        return of(cache as T);
      }
    }
    return super.requestGet<T>(options).pipe(tap((res) => {
      if (optionsStr) {
        this.saveToCache(optionsStr, res);
      }
    }));
  }

  public cachedGetArray(options: DataServiceRequestOptions) {
    return super.requestGetArray<T>(options);
  }

  public cachedGetAll(options: DataServiceRequestOptions = {}) {
    const optionsStr = this.getOptionsStr(options);
    if (optionsStr) {
      const cache = this.simpleCache.get(optionsStr);
      if (cache) {
        // Return from cache.
        return of(cache as PaginatedResponse<T>);
      }
    }
    return super.requestGetAll<T>(options).pipe(tap((res) => {
      if (optionsStr) {
        this.saveToCache(optionsStr, res);
      }
    }));
  }

  public cachedSearch(options: DataServiceRequestOptions = {}): Observable<PaginatedResponse<T>> {
    const optionsStr = this.getOptionsStr(options);
    if (!options.skipCache && optionsStr) {
      const cache = this.simpleCache.get(optionsStr);
      if (cache) {
        // Return from cache.
        return of(cache as PaginatedResponse<T>);
      }
    }
    return super.requestSearch<T>(options).pipe(tap((res) => {
      if (!options.skipCache && optionsStr) {
        this.saveToCache(optionsStr, res);
      }
    }));
  }

  public cachedCreate(item: T, options: DataServiceRequestOptions = {}) {
    return super.requestCreate<T>(item, options).pipe(tap(() => {
      // Clear cache on create.
      this.simpleCache.clear();
    }));
  }

  public cachedPatch(item: T, options: DataServiceRequestOptions = {}) {
    return super.requestPatch<T>(item, options).pipe(tap(() => {
      // Clear cache on patch.
      this.simpleCache.clear();
    }));
  }

  public cachedDelete(options: DataServiceRequestOptions) {
    return super.requestDelete<T>(options).pipe(tap(() => {
      // Clear cache on delete.
      this.simpleCache.clear();
    }));
  }

  public cachedList(options: DataServiceRequestOptions) {
    const optionsStr = this.getOptionsStr(options);
    if (optionsStr) {
      const cache = this.simpleCache.get(optionsStr);
      if (cache) {
        // Return from cache.
        return of(cache as T[]);
      }
    }
    return super.requestList<T>(options);
  }

  getOptionsStr(options: DataServiceRequestOptions): string | undefined {
    let optionsStr: string | undefined = undefined;
    try {
      optionsStr = JSON.stringify(options);
    } catch (error) {
      console.debug('Error converting options to string', options);
    }
    return optionsStr;
  }

  saveToCache(id: string, data: T | PaginatedResponse<T>): void {
    // Save new item.
    this.simpleCache.set(id, data);
    // If cache to big, we should remove oldest item.
    const cacheLength = this.simpleCache.size;
    if (cacheLength > this.CACHE_SIZE) {
      for (const key of this.simpleCache.keys()) {
        this.simpleCache.delete(key);
        break;
      }
    }
  }

  // Type guard to be able to check interface of object
  isOne(toBeDetermined: OneOrMany<T>): toBeDetermined is T {
    if ((toBeDetermined as PaginatedResponse<T>).data) {
      return false;
    }
    return true;
  }
}

type OneOrMany<T> = T | PaginatedResponse<T>;
