import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) { }

  customSuccess(message: string, duration = 3000) : void {
    this.snackBar.open(
      message,
      undefined,
      { duration: duration, panelClass: 'snack-bar-success' }
    )
  }

  customSuccessDark(message: string, duration = 3000) : void {
    this.snackBar.open(
      message,
      undefined,
      { duration: duration, panelClass: 'snack-bar-success-dark' }
    )
  }

  genericErrorUpdate(duration = 3000) : void {
    this.snackBar.open(
      this.translate.instant('generic.error_saving'),
      undefined,
      { duration: duration, panelClass: 'snack-bar-failure' }
    )
  }

  genericErrorGet(duration = 3000) : void {
    this.snackBar.open(
      this.translate.instant('generic.error_get'),
      undefined,
      { duration: duration, panelClass: 'snack-bar-failure' }
    )
  }

  genericErrorDelete(duration = 3000) : void {
    this.snackBar.open(
      this.translate.instant('generic.status_delete_error'),
      undefined,
      { duration: duration, panelClass: 'snack-bar-failure' }
    )
  }

  genericErrorCreate(duration = 3000) : void {
    this.snackBar.open(
      this.translate.instant('generic.status_create_error'),
      undefined,
      { duration: duration, panelClass: 'snack-bar-failure' }
    )
  }

  customError(message: string, duration = 3000) : void {
    this.snackBar.open(
      message,
      undefined,
      { duration: duration, panelClass: 'snack-bar-failure' }
    )
  }

  genericSuccess(duration = 3000) : void {
    this.snackBar.open(
      this.translate.instant('generic.update_success'),
      undefined,
      { duration: duration, panelClass: 'snack-bar-success' }
    )
  }

  genericBadData(duration = 3000) : void {
    this.snackBar.open(
      this.translate.instant('generic.bad_data'),
      undefined,
      { duration: duration, panelClass: 'snack-bar-failure' }
    )
  }

  // Add other snackbar notfiers here
}
