import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name!: string
  role!: string
  image: string | undefined

  constructor(
    private msalUserService: UserService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.msalUserService.currentUser$.pipe(take(1)).subscribe(user => {
      if (user) {
        this.name = user.userName
        this.role = user.role || 'No Role'
        this.msalUserService.accountIconUrl$.pipe(take(1)).subscribe(data => {
          this.image = data
        })
      } else {
        this.name = 'Stefan'
        this.role = 'Admin'
      }
    })
  }

}
