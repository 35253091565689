import { Injectable } from '@angular/core';
import { EventCreateData, EventService } from './event.service';
import { Event } from '@ramboll/rsedt-shared';
import { BehaviorSubject, map, tap } from 'rxjs';
import { DataServiceRequestOptions } from 'src/app/core/classes/data.service.v2';
import { Comment } from '../types/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends EventService<Event> {

  private $comments = new BehaviorSubject<Comment[]>([])

  get comments$() {
    return this.$comments.asObservable()
  }

  getComments(options?: DataServiceRequestOptions, discardPrevious?: boolean) {
    if (discardPrevious) this.$comments.next([])
    this.search(options).pipe(
      map(comments => this.sortByDate(comments.data)))
      .subscribe({
        next: comments => {
          const filteredComments = this.filteredComments(comments);
          this.$comments.next(filteredComments as unknown as Comment[])
        }
      })
  }

  override create(item: EventCreateData, options?: DataServiceRequestOptions) {
    return super.create(item).pipe(tap(() => this.getComments(options)))
  }

  override delete(comment: Comment, options?: DataServiceRequestOptions) {
    return super.delete({ id: comment.id, apiVersion: 2, endpoint: 'event' }).pipe(tap(() => this.getComments(options)))
  }

  trackBy(_index: number, comment: Comment) {
    return comment.id
  }

  private sortByDate(comments: Event[]) {
    return comments.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    })
  }

  /* Filter comments to display in media-manager comments. Only comments added from Portal and comments from task document location
  (when fieldworker executes task successfully)
  are returned here. Other task comments are not included(as they are displayed in tasks list / task card or are not relevant for media manager) */
  filteredComments(comments: Event[]) {
    return comments.filter((comment) => comment.category === 'COMMENT' || (comment.name === 'document_location' && comment.message === 'completed'))
  }
}
