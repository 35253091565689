<div class="media-manager">
  <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start" labelClass="labels"
    (selectedTabChange)="tabChanged($event)" *ngIf="entityType && entityId">
    <mat-tab label="comments" *ngIf="tabsToShow.includes('comments')">
      <ng-template mat-tab-label>
        <mat-icon fontSet="{{activeTab ===  'comments' ? 'material-icons-round' : ''}}">comment</mat-icon>
        {{'common.media-manager.tab-labels.comments' | translate}}
      </ng-template>
      <app-comments [section]="section" [height]="subEntity === 'information' ? 30 : entityType === 'fieldworkAreas' ? 50 : 60" [entityId]="entityId"
        [entityType]="entityType" [subEntity]="subEntity" class="w-100"></app-comments>
    </mat-tab>
    <mat-tab label="images" *ngIf="tabsToShow.includes('images')">
      <ng-template mat-tab-label>
        <mat-icon fontSet="{{activeTab === 'images' ? 'material-icons-round' : ''}}">image</mat-icon>
        {{'common.media-manager.tab-labels.images' | translate}}
      </ng-template>
      <app-device-details-gallery [section]="section" [raskData]="raskData" [showActionButton]="showActionButton"
        class="w-100" [entityId]="entityId" [entityType]="entityType"></app-device-details-gallery>
    </mat-tab>
    <mat-tab label="description" *ngIf="tabsToShow.includes('description')">
      <ng-template mat-tab-label>
        <mat-icon fontSet="{{activeTab === 'description' ? 'material-icons-round' : ''}}">description</mat-icon>
        {{'common.media-manager.tab-labels.documents' | translate}}
      </ng-template>
      <app-documents [section]="section" [location]="location" [subEntity]="subEntity"
        [objectId]="entityId" [entityType]="entityType"></app-documents>
    </mat-tab>
    <mat-tab label="description" *ngIf="tabsToShow.includes('dwg')">
      <ng-template mat-tab-label>
        <mat-icon fontSet="{{activeTab === 'dwg' ? 'material-icons-round' : ''}}">file_open</mat-icon>
        {{'common.media-manager.tab-labels.dwg' | translate}}
      </ng-template>
      <app-dwg [location]="location" [subEntity]="subEntity" [objectId]="entityId"></app-dwg>
    </mat-tab>
  </mat-tab-group>
</div>
