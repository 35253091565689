import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AlertHttpErrorComponent } from './components/alert-http-error/alert-http-error.component';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ApplicationPipesModule } from './pipes/application-pipes/application-pipes.module';
import { SharedMaterialModule } from './shared.material.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MeasurementIconComponent } from './components/measurement-icon/measurement-icon.component';
import { EulaComponent } from './components/eula/eula.component';
import { CustomScrollDirective } from './components/eula/custom-scroll.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDeclineEulaComponent } from './components/confirm-decline-eula/confirm-decline-eula.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ListTitleComponent } from './components/list-title/list-title.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { TableSpinnerComponent } from './components/table-spinner/table-spinner.component';
import { DetailsPageHeaderComponent } from './components/details-page-header/details-page-header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { GalleryComponent } from './components/media-manager/gallery/gallery.component';
import { FileManagerComponent } from './components/media-manager/gallery/file-manager/file-manager.component';
import { MediaManagerComponent } from './components/media-manager/media-manager.component';
import { DocumentsComponent } from './components/media-manager/documents/documents.component';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommentsComponent } from './components/media-manager/comments/comments.component';
import { CommentComponent } from './components/media-manager/comments/comment/comment.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DropdownCardComponent } from './components/dropdown-card/dropdown-card.component';
import { DropdownCardRendererDirective } from './directives/dropdown-card-renderer.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MapComponent } from './components/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PopupMapComponent } from './components/popup-map/popup-map.component';
import { DwgComponent } from './components/media-manager/dwg/dwg.component';
import { MapLegendsComponent } from './components/map/legends/legends.component';
import { MapDialogTabsComponent } from './components/map-dialog-tabs/map-dialog-tabs.component';

@NgModule({
  declarations: [
    LoginPageComponent,
    Error404PageComponent,
    AlertHttpErrorComponent,
    HeaderComponent,
    SideMenuComponent,
    ConfirmDialogComponent,
    MeasurementIconComponent,
    EulaComponent,
    CustomScrollDirective,
    ConfirmDeclineEulaComponent,
    ClickOutsideDirective,
    ListTitleComponent,
    MessageDialogComponent,
    TableSpinnerComponent,
    DetailsPageHeaderComponent,
    MediaManagerComponent,
    GalleryComponent,
    FileManagerComponent,
    DocumentsComponent,
    CommonDialogComponent,
    CommentsComponent,
    CommentComponent,
    DropdownCardComponent,
    DropdownCardRendererDirective,
    MapComponent,
    PopupMapComponent,
    DwgComponent,
    MapLegendsComponent,
    MapDialogTabsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedMaterialModule,
    TranslateModule,
    ApplicationPipesModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    OverlayModule,
    LeafletModule
  ],
  exports: [
    SharedMaterialModule,
    LoginPageComponent,
    Error404PageComponent,
    AlertHttpErrorComponent,
    HeaderComponent,
    SideMenuComponent,
    ApplicationPipesModule,
    ConfirmDialogComponent,
    MeasurementIconComponent,
    ClickOutsideDirective,
    ListTitleComponent,
    TableSpinnerComponent,
    DetailsPageHeaderComponent,
    MediaManagerComponent,
    DropdownCardRendererDirective,
    MapComponent,
    PopupMapComponent,
    MapLegendsComponent,
    MatTabsModule
  ],
  entryComponents: [
    ConfirmDeclineEulaComponent,
    MapDialogTabsComponent
  ]
})
export class SharedModule { }
