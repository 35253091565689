import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private opened = false;
  private enabled = false;

  private $opened = new ReplaySubject<boolean>(1);
  private $enabled = new ReplaySubject<boolean>(1);

  public open$ = this.$opened.asObservable().pipe(distinctUntilChanged());
  public enabled$ = this.$enabled.asObservable().pipe(distinctUntilChanged());

  constructor() {
    this.$opened.next(false);
    this.$enabled.next(false);
  }

  /**
   * Returns menu state: open or closed.
   * @returns true if menu is opened, else false
   */
  public isOpen() {
    return this.opened;
  }

  /**
   * Returns menu state: enabled or disabled.
   * @returns true if menu is opened, else false
   */
  public isEnabled() {
    return this.enabled;
  }

  /**
   * Toggle menu state between open and closed.
   * @returns true if menu is opened, else false
   */
  toggle(): boolean {
    this.opened = !this.opened;
    this.$opened.next(this.opened);
    return this.opened;
  }

  /**
   * Toggle menu state to open. Does nothing if already opened.
   * @returns true if menu is opened, else false
   */
  open() {
    this.opened = true;
    this.$opened.next(true);
    return true;
  }

  /**
   * Toggle menu state to closed. Does nothing if already closed.
   * @returns true if menu is opened, else false
   */
  close() {
    this.opened = false;
    this.$opened.next(false);
    return false;
  }

  /**
   * Set menu button visible state to true.
   * @returns true if menu is enabled, else false.
   */
  enable() {
    this.enabled = true;
    setTimeout(() => this.$enabled.next(true), 0);
    return true;
  }

  /**
   * Set menu button visible state to true.
   * @returns true if menu is enabled, else false.
   */
  disable() {
    this.enabled = false;
    setTimeout(() => this.$enabled.next(false), 0);
    return false;
  }

}
