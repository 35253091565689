<mat-toolbar>
  <div class="account">
    <ul>
      <li class="name">{{name}}</li>
      <li class="role">{{role}}</li>
    </ul>
    <div class="avatar">
      <mat-icon *ngIf="!image">person</mat-icon>
      <img *ngIf="image" [src]="image" alt="" srcset="">
    </div>

    <!-- <ng-container *ngIf="!!(userService.loggedIn$ | async); else loggedOut">
      <ul>
        <li class="name">{{ userService.name$ | async }}</li>
        <ng-container *ngIf="(userService.role$ | async) || 'User' as role">
          <li class="role">{{ 'auth.roles.' + role | translate }}</li>
        </ng-container>
      </ul>
      <div class="avatar">
        <img [src]="userService.accountIconUrl$ | async" />
      </div>
    </ng-container>

    <ng-template #loggedOut>
      <div class="avatar">
        <mat-icon>person</mat-icon>
      </div>
    </ng-template> -->

  </div>
</mat-toolbar>
