import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogLevel } from 'msal';
import { DeepLinkService } from './core/services/deep-link.service';
import { MapDialogTabsComponent } from './shared/components/map-dialog-tabs/map-dialog-tabs.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.OAUTH_CLIENTID,
      authority: environment.OAUTH_AUTHORITY,
      redirectUri: environment.OAUTH_REDIRECT_URL,
      postLogoutRedirectUri: environment.OAUTH_REDIRECT_URL,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback: () => {return ;},
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map([
    [`${environment.OAUTH_GRAPH_URL}/me`, ['user.read', 'openid', 'profile']],
    [environment.API_BASE_URL, [environment.API_BASE_CLAIM]]
  ])

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory() {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [AppComponent, ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DeepLinkService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
