import { AbstractLocationObject } from './location_object';

/**
 * Address ...
 */
export interface Address {
  addressLine?: string;
  postalCode?: string;
  locality?: string;
  country?: string;
}
/**
 * Position describes the geographic coordinates of a location. System property describes how
 * to interpret the latitude and longitude given. An optional alt parameter is available to
 * position along the z-axis according to the given system.
 *
 * If system is blank it is assumed to be WGS84
 */
export interface Position {
  system: string;
  lat: number;
  lng: number;
  alt?: number;
}

export interface GeoLocation {
  coordinates: any,
  type: string
}

export interface Measurements {
  initiativeIds: any,
  measurementId: string,
  typeId?: string,
  name?: string,
  notes?: string,
  isManual: boolean,
  status: string,
  locationId?: string,
  createdAt?: Date
}

/**
 * Location is recognized by its geographic coordinates and may have an address associated with it
 * A name is required, but description and area are optional - there to help identify the location.
 */
export class AbstractLocation {
  public locationId: string;
  /**
   * Name of the location.
   */
  public name: string;
  /**
   * IoT devices at the location. TODO: Move to structural location object e.g. CABINET?
   */
  public deviceIds?: string[];
  public position: Position;

  public geoLocation?: GeoLocation;

  public isManual?: boolean; // automatically assigned by locationsService.locations$ pipe

  /**
   * Free text field to describe the location
   */
  public description?: string;
  /**
   * The area is a free text field to group locations into areas
   */
  public area?: string;

  public objects?: AbstractLocationObject[];

  public address?: Address;

  public createdAt?: Date;
  public updatedAt?: Date;

  public measurementIds: string[];
  public measurements?: Measurements[];

  public fieldworkAreaId: string;

  constructor(input: AbstractLocation) {
    this.locationId = input.locationId;
    this.name = input.name;
    this.deviceIds = input.deviceIds;
    this.position = input.position;
    this.geoLocation = input.geoLocation;
    this.isManual = input.isManual;
    this.description = input.description;
    this.area = input.area;
    this.objects = input.objects;
    this.address = input.address;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
    this.measurementIds = input.measurementIds;
    this.measurements = input.measurements;
    this.fieldworkAreaId = input.fieldworkAreaId;
  }
}
