import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpNotification } from '../interfaces/notifications.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private $http = new Subject<HttpNotification>();
  private $error = new Subject<string>();
  private $warn = new Subject<string>();
  private $info = new Subject<string>();
  private $reportError = new Subject<string>();
  private $reportSuccess = new Subject<string>();

  public http$ = this.$http.asObservable();
  public error$ = this.$error.asObservable();
  public warn$ = this.$warn.asObservable();
  public info$ = this.$info.asObservable();
  public reportError$ = this.$reportError.asObservable();
  public reportSuccess$ = this.$reportSuccess.asObservable();

  constructor() { }

  /**
   * Emit a HTTP error.
   * @param source Resource where error occured.
   * @param error HttpErrorResponse object.
   */
  public http(source: string, error: HttpErrorResponse): void {
    this.$http.next([source, error]);
  }

  /**
   * Emit a warning.
   * @param message Warning message.
   */
  public warn(message: string): void {
    this.$warn.next(message);
  }

  /**
   * Emit an error.
   * @param message Error message.
   */
  public error(message: string): void {
    this.$error.next(message);
  }

  /**
   * Emit information.
   * @param message Information message.
   */
  public info(message: string): void {
    this.$info.next(message);
  }

  /**
   * Emit a report error.
   * @param message Error message.
   */
  public reportError(message: string): void {
    this.$reportError.next(message);
  }

  /**
   * Emit a report success.
   * @param message Information message.
   */
  public reportSuccess(message: string): void {
    this.$reportSuccess.next(message);
  }

}
