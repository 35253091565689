<mat-sidenav-container *ngIf="authService.instance.getAllAccounts().length > 0 && !router.url.includes('login'); else notLoggedIn"> <!--logged in-->'
  <mat-sidenav #sidenav mode="side" opened [fixedInViewport]="true">
    <app-side-menu></app-side-menu>
  </mat-sidenav>
  
  <mat-sidenav-content>
    <app-header></app-header>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #notLoggedIn> <!-- Not logged in -->
  <router-outlet></router-outlet>
</ng-template>
