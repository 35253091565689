import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@ramboll/rsedt-shared';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { FileService } from 'src/app/shared/services/file.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DocumentFile } from 'src/app/shared/models/documentfile';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { AbstractDocumentFile, FileType } from 'src/app/shared/types/documentfile';

@Component({
  selector: 'app-dwg',
  templateUrl: './dwg.component.html',
  styleUrls: ['./dwg.component.scss']
})
export class DwgComponent {
  @Input() objectId!: string; // eg. deviceId
  @Input() subEntity!: string;
  @Input() location: Location | undefined
  uploadLoading = false;
  documentType: FileType = "geojson";

  constructor (
    public dialog: MatDialog,
    public fileService: FileService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) { }

  updateDWG(event: any, document: DocumentFile, property: 'showInInitiativeMap' | 'showInProjectMap') {

    const copy = { ...document } as DocumentFile
    if (property === 'showInInitiativeMap') {
      copy.showInInitiativeMap = event.checked
    }
    if (property === 'showInProjectMap') {
      copy.showInProjectMap = event.checked
    }

    this.fileService.patchDWG(copy)
  }

  // Todo: Handle logic for uploading DWG
  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement
    const file: File | undefined = target?.files?.[0];
    if (file) {
      this.uploadLoading = true;

      const body: AbstractDocumentFile = {
        "objectId": this.objectId,
        "objectType": "location", // Logic in backend forced this to be 'location'
        "documentType": this.documentType,
        "category": 'general', // default file category
      };

      this.fileService.create(body, file).pipe(take(1)).subscribe({
        next: () => {
          this.uploadLoading = false
          this.snackbarService.customSuccessDark(`${this.translate.instant('common.media-manager.documents.file_uploaded')}`)
        },
        error: () => {
          this.uploadLoading = false
          this.snackbarService.customError(`${this.translate.instant('common.media-manager.documents.uploading_error')}`)
        }
      })

    }
  }

  // Todo: Handle logic for removing DWG
  removeDialog(document: any) {
    const dialogData = {
      title: 'common.media-manager.documents.delete_dialog_title',
      items: [document.originalname],
      message: 'common.media-manager.documents.delete_dialog_text',
      buttons: [{
        text: 'common.cancel',
        value: false,
        type: 'stroked',
        color: 'not-primary'
      },
      {
        text: 'common.delete',
        value: true,
        type: 'flat'
      }]
    };

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: dialogData,
      width: '375px'
    },);

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.remove(document);
      }
    });
  }

  remove(document: DocumentFile): void {
    if (document) {
      this.fileService.remove(document).pipe(take(1)).subscribe({
        next: () => {
          this.snackbarService.customSuccessDark(this.translate.instant('common.media-manager.documents.file_deleted'))
        },
        error: () => {
          this.snackbarService.genericErrorDelete()
        }
      })
    }
  }
}
