import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})


export class LoginPageComponent implements OnInit, OnDestroy {

  subscription!: Subscription;

  constructor(
    public userService: UserService,
    public route: Router,
    private deepLinkService: DeepLinkService
  ) { }

  ngOnInit(): void {
    this.subscription = this.userService.currentUser$.subscribe((user) => {
      const currentAccount = this.userService.getAccountInfo();
      if (currentAccount && user?.approvedEula) {
        // Check if the login came from deep link.
        if (this.deepLinkService.getPathName()) {
          this.route.navigate([this.deepLinkService.getPathName()], { queryParams: this.deepLinkService.getParams() });
          this.deepLinkService.removeDeepLink();
          return;
        }
        this.route.navigateByUrl('locations');
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
