import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface DataServiceRequestOptions {
  id?: string;
  resource?: string;
  endpoint?: string;
  suffix?: string;
  params?: Record<string, string | string[] | number | boolean>;
  apiVersion?: number;
  addId?: boolean;
  rawRequest?: string;
  skipCache?: boolean;
}

export interface MetaData {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface PaginatedResponse<T> {
  meta: MetaData;
  data: T[];
}

export abstract class DataServiceV2 {

  constructor(
    protected http: HttpClient,
    protected resource = '/',
  ) { }

  protected requestGet<T>(options: DataServiceRequestOptions): Observable<T> {
    if (!options.rawRequest && !options.id) {
      throw new Error('DataService.requestGet(): ID required.');
    }
    // If rawRequest then just pass request through.
    if (options.rawRequest) {
      return this.http.get<T>(options.rawRequest);
    }
    return this.http
      .get<T>(this.generateUrl(options), { params: options.params });
  }

  protected requestGetArray<T>(options: DataServiceRequestOptions): Observable<T[]> {
    if (!options.id) {
      throw new Error('DataService.requestGet(): ID required.');
    }
    return this.http
      .get<T[]>(this.generateUrl(options), { params: options.params });
  }

  protected requestGetAll<T>(options: DataServiceRequestOptions = {}): Observable<PaginatedResponse<T>> {
    return this.http
      .get<PaginatedResponse<T>>(this.generateUrl(options), { params: options.params });
  }

  protected requestSearch<T>(options: DataServiceRequestOptions = {}): Observable<PaginatedResponse<T>> {
    return this.http
      .get<PaginatedResponse<T>>(this.generateUrl(options), { params: options.params });
  }

  protected requestSearchFile(options: DataServiceRequestOptions = {}): Observable<Blob> {
    return this.http.get(this.generateUrl(options), { params: options.params, responseType: 'blob' });
  }

  protected requestCreate<T>(data: T, options: DataServiceRequestOptions): Observable<T> {
    return this.http
      .post<T>(this.generateUrl(options), data, { params: options.params });
  }

  protected requestPatch<T>(data: T, options: DataServiceRequestOptions): Observable<T> {
    if (!options.id) {
      throw new Error('DataService.requestUpdate(): ID required.');
    }
    return this.http
      .patch<T>(this.generateUrl(options), data, { params: options.params });
  }

  protected requestDelete<T>(options: DataServiceRequestOptions) {
    if (!options.id) {
      throw new Error('DataService.requestDelete(): ID required.');
    }
    return this.http
      .delete<T>(this.generateUrl(options), options);
  }

  protected requestList<T>(options: DataServiceRequestOptions) {
    return this.http.get<T[]>(this.generateUrl(options), { params: options.params });
  }

  protected generateUrl({ id, resource = this.resource, apiVersion = 2, endpoint, suffix, addId = true }: DataServiceRequestOptions) {
    return [environment.API_BASE_URL, environment.localhost ? `v${apiVersion}` || apiVersion === 1 : '', resource, apiVersion === 1 ? '' : `v${apiVersion}`, endpoint, addId ? encodeURIComponent(id || '') : '', suffix ? suffix : '']
      .filter(x => !!x).join('/').split('://').map(p => p.replace(/\/\//, '/')).join('://');
  }
}
