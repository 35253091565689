import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private inflightRequests = 0;
  private progressTotal = 0;
  private progressDone = 0;

  private $loading = new ReplaySubject<boolean>(1);
  private $progress = new ReplaySubject<number>(1);

  public loading$ = this.$loading.pipe(distinctUntilChanged());
  public ready$ = this.$loading.pipe(map(loading => !loading), distinctUntilChanged());
  public progress$ = this.$progress.pipe(distinctUntilChanged());

  constructor() {}

  /**
   * Returns number of currently tracked requests.
   * @returns number of tracked requests.
   */
  public loading(): number {
    return this.inflightRequests;
  }

  /**
   * Check if there are active requests.
   * @returns true if there are active requests, else false.
   */
  public isLoading(): boolean {
    return this.inflightRequests > 0;
  }

  /**
   * Check if there are no active requests.
   * @returns true if there are no active requests, else false.
   */
  public isReady(): boolean {
    return !this.isLoading();
  }

  /**
   * Increase the number of active requests by 1.
   * @returns number of tracked requests.
   */
  public add(): number {
    this.inflightRequests++;
    this.progressTotal++;
    // console.log('this.inflightRequests', this.inflightRequests);
    this.$progress.next(this.getProgressPercent());
    this.$loading.next(this.inflightRequests > 0);
    return this.inflightRequests;
  }

  /**
   * Decreases the number of active requests by 1.
   * @returns number of tracked requests.
   */
  public done(): number {
    this.inflightRequests = Math.max(--this.inflightRequests, 0);
    this.progressDone++;
    // console.log('this.inflightRequests', this.inflightRequests);
    if (this.inflightRequests > 0) {
      this.$loading.next(true);
    } else {
      // delay final loading event by 250 ms to match loading bar animation
      setTimeout(() => this.$loading.next(false), 250);
    }
    this.$progress.next(this.getProgressPercent());
    if (this.progressDone === this.progressTotal) {
      this.progressDone = 0;
      this.progressTotal = 0;
    }

    return this.inflightRequests;
  }

  /**
   * Gets percentage of completed currently tracked requests. Resets when number of tracked requests becomes 0.
   * @returns percentage done of tracked requests as a value between 0-100.
   */
  private getProgressPercent() {
    return (this.progressDone || 0) / (this.progressTotal || 100) * 100;
  }
}
