import { Injectable } from '@angular/core';
import { RaskProject } from '@ramboll/rsedt-shared';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private _raskProjects = new BehaviorSubject<RaskProject[]>([])
  public raskProjects$ = this._raskProjects.asObservable()
  public setRaskProjects(projects: RaskProject[]) {
    this._raskProjects.next(projects);
  }

  constructor() { }
}
