<div class="image-actions"> 
  <button class="delete-button" (click)="openDeleteDialog()" mat-flat-button>
    <span *ngIf="!deleteLoading">{{'common.delete' | translate}}</span>
    <mat-spinner class="spinner" *ngIf="deleteLoading" diameter="20" ></mat-spinner>
  </button>
  <button class="upload-button" [ngClass]="uploadLoading ? 'ongoing-action' : 'normal-action'" mat-stroked-button color="primary">
    <div>
      <label class="clickable" for="files" *ngIf="!uploadLoading">
        <mat-icon >file_upload</mat-icon>{{'common.picture-manager.upload' | translate}}
        <input class="clickable" #imageInput id="files" accept="image/*" (change)="onFileSelected($event)" style="display: none;" type="file">
      </label>
      <ng-container *ngIf="uploadLoading">
        <mat-spinner class="spinner" diameter="20" ></mat-spinner>{{'common.picture-manager.uploading_image' | translate}}
      </ng-container>
    </div>
  </button>
</div>
<ng-template #deleteImage>
  <div class="confirm-dialog">
    <h1 mat-dialog-title>{{'common.picture-manager.delete_dialog_title' | translate}}</h1>
    <div mat-dialog-content class="confirm-dialog-content">
      <p>{{'common.picture-manager.delete_dialog_text' | translate}}</p>
    </div>
    <div mat-dialog-actions class="dialog-actions">
        <button mat-flat-button (click)="close()">{{'common.picture-manager.cancel' | translate}}</button>
        <button mat-stroked-button class="delete-button" (click)="deleteFile()">{{'common.picture-manager.confirm' | translate}}</button>
    </div>
  </div>
</ng-template>