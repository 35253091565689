import { ID, Model } from 'src/app/core/interfaces/model.interface';
import { AbstractLocation } from '../types/location';
import { LocationObject } from './location-object';

export class Location extends AbstractLocation implements Model {
  id!: ID;
  override objects: LocationObject[];
  deserialize(input: AbstractLocation, id?: ID): this {
    return Object.assign(
      this,
      input,
      id ? { id } : {},
      { objects: input.objects ? input.objects.map(obj => new LocationObject(obj).deserialize(obj)) : [] }
    );
  }

  constructor(input: AbstractLocation) {
    super(input);
    this.objects = input.objects as LocationObject[]
  }
}
