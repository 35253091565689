import { Component, Input, inject, OnInit, OnDestroy } from '@angular/core';
import { FileService } from 'src/app/shared/services/file.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AbstractDocumentFile, FileCategories, FileType } from 'src/app/shared/types/documentfile';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { DocumentFile } from 'src/app/shared/models/documentfile';
import { Location } from '@ramboll/rsedt-shared';
import { ControlContainer, FormGroup } from '@angular/forms';
import { LocationsService2 } from 'src/app/locations/services/locations.service2';
import { UserService } from 'src/app/shared/services/user.service';
import { FileTypeEntities, MediaManagerEntities } from '../media-manager.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @Input() objectId!: string; // eg. deviceId
  @Input() subEntity!: string;
  @Input() location: Location | undefined
  @Input() section?: string
  @Input() entityType!: FileTypeEntities

  private msalUserService = inject(UserService)
  public isClient: boolean = false;

  uploadLoading = false;
  documentType: FileType = "pdf";
  allowReport = false
  documentCategories = ['general', 'calibration', 'report', 'manual']
  metaDataForm!: FormGroup
  selected = 'general'
  subscriptions = new Map<string, Subscription>();

  constructor(
    public fileService: FileService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private controlContainer: ControlContainer,
    private locationsService: LocationsService2
  ) { }

  // Manage logic for category changes, both for the file and location
  onCategoryChange(event: any, document: DocumentFile) {
    const oldCategory = document.category
    document.category = event.value
    this.fileService.updateMediaCategory(document).pipe(take(1)).subscribe({
      next: () => {
        this.uploadLoading = false
        this.snackbarService.customSuccessDark(`${this.translate.instant('common.media-manager.documents.file_updated')}`)

        if (this.location?.type === 'energy_well') {
          this.updateLocation(document, oldCategory ?? 'general', oldCategory === 'report')
        }
      },
      error: () => {
        this.uploadLoading = false
        this.snackbarService.customError(`${this.translate.instant('common.media-manager.documents.uploading_error')}`)
      }
    })
  }

  updateLocation(document: DocumentFile, oldCategory?: FileCategories, remove?: boolean) {
    // New category is 'report'. Add reportCreated to location
    if (this.location && document.category === 'report' && !remove) {
      this.reportCreated?.setValue(true)
      this.reportCreatedDate?.setValue(document.createdAt ?? new Date().toISOString())
      this.locationsService.patch(this.locationForm.value, { id: this.location.locationId }).pipe(take(1)).subscribe()
    }
    // Switch from report to other. Remove reportCreated from location
    else if (this.location && oldCategory === 'report' && remove) {
      this.reportCreated?.setValue(false)
      this.reportCreatedDate?.setValue(null)
      this.locationsService.patch(this.locationForm.value, { id: this.location.locationId }).pipe(take(1)).subscribe()
    }
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement
    const file: File | undefined = target?.files?.[0];
    if (file) {
      this.uploadLoading = true;

      const body: AbstractDocumentFile = {
        "objectId": this.objectId,
        "objectType": "location", // Logic in backend forced this to be 'location'
        "documentType": this.documentType,
        "category": 'general' // default file category
      };

      this.fileService.create(body, file).subscribe({
        next: () => {
          this.uploadLoading = false
          this.snackbarService.customSuccessDark(`${this.translate.instant('common.media-manager.documents.file_uploaded')}`)
        },
        error: () => {
          this.uploadLoading = false
          this.snackbarService.customError(`${this.translate.instant('common.media-manager.documents.uploading_error')}`)
        }
      })

    }
  }

  removeDialog(document: DocumentFile) {
    const dialogData = {
      title: 'common.media-manager.documents.delete_dialog_title',
      items: [document.originalname],
      message: 'common.media-manager.documents.delete_dialog_text',
      buttons: [{
        text: 'common.cancel',
        value: false,
        type: 'stroked',
        color: 'not-primary'
      },
      {
        text: 'common.delete',
        value: true,
        type: 'flat'
      }]
    };

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: dialogData,
      width: '375px'
    },);

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.remove(document);
        if (document.category === 'report') {
          this.updateLocation(document, 'report', true)
        }
      }
    });
  }

  remove(document: DocumentFile): void {
    if (document) {
      this.fileService.remove(document).pipe(take(1)).subscribe({
        next: () => {
          this.snackbarService.customSuccessDark(this.translate.instant('common.media-manager.documents.file_deleted'))
        },
        error: () => {
          this.snackbarService.genericErrorDelete()
        }
      })
    }
  }

  //open PDF in new tab
  openPdf(documentFile: DocumentFile) {
    if (documentFile.fileName) {
      const { fileName } = documentFile;
      this.fileService.downloadBlob(fileName).pipe(take(1))
        .subscribe((data) => {
          const dataType = data.type;
          const binaryData = [];
          binaryData.push(data);
          const fileURL = URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          window.open(fileURL, '_blank');
        })
    }
  }

  downloadDocument(documentFile: DocumentFile) {
    const { fileName, originalname } = documentFile;

    if (!fileName) {
      return;
    }

    this.fileService.downloadBlob(fileName).pipe(take(1))
      .subscribe(async (data) => {
        const { type } = data;
        const blob = new Blob([data], { type });
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element and trigger the download
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = originalname ?? fileName;
        document.body.appendChild(a);
        a.click();

        // Clean up: Remove the anchor and revoke the Blob URL
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      });
  }

  disableToolTip(category: string, allowCreate: string): boolean {
    if (category === 'report' && allowCreate === 'false') {
      return false
    }
    return true
  }

  get locationForm(): FormGroup { return this.controlContainer.control as FormGroup }
  get reportCreated() { return this.locationForm?.get(['metadata', 'reportCreated']) }
  get reportCreatedDate() { return this.locationForm?.get(['metadata', 'reportCreatedDate']) }

  ngOnInit(): void {
    this.subscriptions.set(
      'isClient',
      this.msalUserService.isClient$.subscribe(isClient => this.isClient = isClient)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  shouldDisplay(): boolean {
    if (this.entityType === MediaManagerEntities.PROJECTS) {
      return true;
    }

    return !this.isClient;
  }
}
