<div class="bg-image">
  <div class="login-container">
    <img class="logo" src="../../../../assets/logo.jpg" alt="ramboll-logo" />
    <h2>{{ 'login.header' | translate }}</h2>
    <p class="sub-title">{{ 'login.subtitle' | translate | uppercase}}</p>
    <!-- No AAD user logged in. Show log in button -->
    <ng-container *ngIf="!userService.getAccountInfo(); else logIn">
      <button class="button" mat-flat-button (click)="userService.login()">
        <img class="microsoft-logo" src="../../../../assets/microsoft.svg" alt="microsoft-logo" />
        {{ 'login.sign_in_microsoft' | translate }}
      </button>
    </ng-container>
    <ng-template #logIn>
      <!-- User logged in. Check if there is a db user for that AAD user. Else show no account error. -->
      <ng-container *ngIf="this.userService.currentUser$ | async as user; else noAccount">
        <!-- Check if user has approved eula. If not, show eula -->
        <ng-container *ngIf="!user.approvedEula">
          <app-eula></app-eula>
        </ng-container>
      </ng-container>
    </ng-template>
    <div class="line"></div>
    <p class="width">
      {{ 'login.text' | translate }}
      <a href="mailto:loginhelp@ramboll.se" target="_blank" rel="noopener noreferrer">loginhelp@ramboll.se</a>
    </p>
  </div>
</div>
<ng-template #noAccount>{{ 'login.no_account' | translate }}</ng-template>
