import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { LoggerService as log } from '../services/logger.service';

export class RequestsInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingService
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // keep track of the number of pending requests
    this.loadingService.add();
    // keep track of status and elapsed time
    const sent = Date.now();
    let ok: string;
    let code: number;

    return next
      .handle(req).pipe(
        tap({
          next: event => {
            ok = event instanceof HttpResponse ? 'OK' : '';
            code = event instanceof HttpResponse ? event.status : -1;
          },
          error: event => {
            ok = 'FAIL';
            code = event instanceof HttpErrorResponse ? event.status : -1;
          }
        }),
        finalize(() => {
          this.loadingService.done();
          const elapsed = Date.now() - sent;
          log.debug(`${req.method} ${req.urlWithParams} ${ok} ${code} (${elapsed} ms).`);
        })
      );
  }
}
