import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  public setDeepLink(link: string): void {
    if (!sessionStorage.getItem('deepLink') && link !== '/') {
      sessionStorage.setItem('deepLink', link);
    }
  }

  public removeDeepLink(): void {
    sessionStorage.removeItem('deepLink');
  }

  public getPathName(): string {
    return sessionStorage.getItem('deepLink')?.split('?')?.[0] || 'locations';
  }

  public getParams(): { [key: string]: string } {
    const search = sessionStorage.getItem('deepLink')?.split('?')[1];
    const params: { [key: string]: string } = {};
    if (search) {
      search.split(',').forEach(param => {
        params[param.split('=')[0]] = param.split('=')[1];
      })
    }
    return params;
  }

}