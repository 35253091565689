import { Component, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FileService } from '../../services/file.service';
import { Fastighet, Location } from '@ramboll/rsedt-shared';
import { UserService } from '../../services/user.service';

export type FileTypeEntities = 'locations' | 'devices' | 'fieldworkAreas' | 'projects' | 'information' | 'initiatives'

export type FileTypeEntitiesSingle = 'location' | 'device' | 'fieldworkArea' | 'workArea' | 'project' | 'initiative'

export enum entitySingle {
  'locations' = "location",
  'devices' = "device",
  'fieldworkAreas' = "workArea",
  'projects' = "project",
  'information' = 'information',
  'initiative' = 'initiative'
}

export enum MediaManagerEntities {
  LOCATIONS = "locations",
  DEVICES = "devices",
  WORKAREAS = "fieldworkAreas",
  PROJECTS = "projects",
  INITIATIVES = 'initiatives'
}

@Component({
  selector: 'app-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaManagerComponent implements OnInit {
  @Input() entityId: string | undefined
  @Input() raskData!: Fastighet
  @Input() entityType!: FileTypeEntities
  @Input() location: Location | undefined
  @Input() subEntity = "Locations"
  mediaManagerEntities = MediaManagerEntities
  activeTab = ''
  @Input() tabsToShow = [''];
  @Input() showActionButton = true;
  @Input() section?: string

  private msalUserService = inject(UserService)
  isClient$ = this.msalUserService.isClient$

  constructor(private fileService: FileService) { }

  ngOnInit(): void {
    if (this.raskData === undefined && this.entityId) {
      this.fileService.setEntityId(this.entityId);
      this.fileService.setEntityType(this.entityType);
      if (this.entityType === MediaManagerEntities.LOCATIONS) {
        this.activeTab = "comments"
      } else {
        this.activeTab = "images"
      }
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.activeTab = event.tab.textLabel
  }
}
