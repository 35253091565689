<!-- Header -->
<div class="header">
    <p class="start">{{ 'common.media-manager.dwg.name' | translate }}</p>
    <p>{{ 'common.media-manager.dwg.in_initiative_map' | translate }}</p>
    <p>{{ 'common.media-manager.dwg.in_project_map' | translate }}</p>
    <p class="margin-right">{{ 'common.media-manager.dwg.uploaded' | translate }}</p>
    <p></p>
</div>

<!-- Documents list -->
<div class="loading" *ngIf="fileService.loading$ | async; else ready">
    <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
</div>

<ng-template #ready>
    <div class="information-list">
        <div class="list-item" *ngFor="let document of fileService.dwgs$ | async" >
            <div class="border-container">
                <p class="link-look">{{ document.originalname }}</p>
                <mat-checkbox
                    class="ml-2"
                    color="primary" 
                    [checked]="document.showInInitiativeMap" 
                    (change)="updateDWG($event, document, 'showInInitiativeMap')"
                ></mat-checkbox>
                <mat-checkbox
                    class="ml-3"
                    color="primary" 
                    [checked]="document.showInProjectMap" 
                    (change)="updateDWG($event, document, 'showInProjectMap')"
                ></mat-checkbox>
                <p class="ml-1">{{ document.createdAt | date: 'yyyy-MM-dd' }}</p>
                <mat-icon (click)="removeDialog(document)">close</mat-icon>
            </div>
        </div>
    </div>
</ng-template>

<div class="footer">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload">
        <button *ngIf="!uploadLoading" matButton mat-flat-button (click)="fileUpload.click()">
            <mat-icon>file_upload</mat-icon>
            {{ 'common.media-manager.dwg.upload_dwg' | translate }}
        </button>
        <button *ngIf="uploadLoading" matButton mat-flat-button class="uploading-btn" class="uploading">
            <div>
                <mat-spinner diameter="20" class="spinner"></mat-spinner>
                <p>{{ 'common.media-manager.documents.uploading_file' | translate }}...</p> 
            </div>
        </button>
    </div>
</div>

