import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonDialogButtons, CommonDialogConfig } from '../../types/common-dialog.interface';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent {

  trackByString = (_index: number, item: string) => item;
  trackByButton = (_index: number, item: CommonDialogButtons) => item.text;

  constructor(public dialogRef: MatDialogRef<CommonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CommonDialogConfig) { }

  onClick(value: unknown): void {
    this.dialogRef.close(value)
  }
}