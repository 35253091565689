<img src="../../../../assets/logo.svg" alt="Logo" width="126" height="28" />
<h1>{{ 'common.app_name' | translate }}</h1>

<mat-nav-list>
  <ng-container *ngFor="let link of links$ | async">
    <a mat-list-item *ngIf="!link.hidden" [routerLink]="link.disabled ? null : link.link" routerLinkActive="active"
      [disabled]="link.disabled"><mat-icon class="material-icons-outlined">{{ link.icon }}</mat-icon> {{ link.label |
      translate }}
    </a>
  </ng-container>
</mat-nav-list>
<mat-nav-list class="bottom-items">
  <ng-container *ngFor="let link of bottomLinks">
    <a *ngIf="link.icon === 'person_pin'" mat-list-item [routerLink]="link.link" routerLinkActive="active"
      [disabled]="link.disabled">
      <mat-icon class="material-icons-outlined">{{ link.icon }}</mat-icon> {{ link.label | translate }}
    </a>
    <a *ngIf="link.icon === 'logout'" mat-list-item (click)="logout()" routerLinkActive="active"
      [disabled]="link.disabled">
      <mat-icon class="material-icons-outlined">{{ link.icon }}</mat-icon> {{ link.label | translate }}
    </a>
  </ng-container>

</mat-nav-list>