import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RequestsInterceptor } from './interceptors/requests.interceptor';
import { ApplicationInsightsService } from './services/application-insights.service';
import { LoadingService } from './services/loading.service';
import { LoggerService } from './services/logger.service';
import { MenuService } from './services/menu.service';
import { NotificationService } from './services/notification.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule
  ],
  providers: [
    ApplicationInsightsService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true, deps: [LoadingService, NotificationService] },
    MenuService,
    NotificationService,
    LoadingService,
    LoggerService,
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
