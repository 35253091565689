import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeclineEulaComponent } from '../confirm-decline-eula/confirm-decline-eula.component';
import { UserService } from '../../services/user.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent {

  constructor(
    private msalUserService: UserService,
    private router: Router,
    private deepLinkService: DeepLinkService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) { }

  @ViewChild('scroll', { read: ElementRef }) public scroll!: ElementRef<HTMLElement>;

  acceptEula() {
    this.msalUserService.currentUser$.pipe(take(1)).subscribe(user => {
      if (user) {
        user.approvedEula = true;
        user.gdprApproved = true;
        this.msalUserService.patchCurrentUser(user)
          .pipe(take(1)) // redirect once
          .subscribe({
            next: () => {
              // Check if the original login came from deep link. If so, clear the deep link record.
              if (this.deepLinkService.getPathName()) {
                this.deepLinkService.removeDeepLink();
                this.router.navigate([this.deepLinkService.getPathName()], { queryParams: this.deepLinkService.getParams() });
              }
              this.router.navigateByUrl('locations');
            },
            error: () => {
              this.snackbarService.customError(this.translate.instant('users.generic.eula_update_failure'))
            }
          })
      } else {
        this.snackbarService.customError(this.translate.instant('users.generic.eula_failure'))
      }
    })
  }

  confirmDeclineEula() {
    this.dialog.open(ConfirmDeclineEulaComponent, {
      width: '350px',
      height: '240px'
    });
  }

}
