import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { DurationPipe } from './duration.pipe';
import { UpperFirstPipe } from './upperFirst.pipe';
import { UnitConvertPipe } from './unit-convert.pipe';



@NgModule({
  declarations: [
    SafePipe,
    DurationPipe,
    UpperFirstPipe,
    UnitConvertPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    DurationPipe,
    UpperFirstPipe,
    UnitConvertPipe
  ]

})
export class ApplicationPipesModule { }
