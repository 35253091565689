import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ID, Model } from 'src/app/core/interfaces/model.interface';
import { Location } from 'src/app/shared/models/location';
import { NotificationService } from '../services/notification.service';
import { DataService, DataServiceIdRequestOptions, DataServiceRequestOptions } from './data.service';

export abstract class CachedSparseDataService<Item extends Model, Sparse extends Model> extends DataService {

  // sparse items are from GET /resources and might miss fields to save bandwidth
  private sparseItems: Record<ID, Sparse> = {}; // TODO: use memcache, indexed db, local storage or something
  protected readonly $sparseItems = new ReplaySubject<Sparse[]>(1);
  protected readonly sparseItems$ = this.$sparseItems.pipe(shareReplay(1));

  // items are complete records from GET /resources/:id.
  // use peek() to access cached copies them without sending a new request. peek() will get() if item hasn't been cached.
  // use get() to send a new GET request.
  private $item: Record<ID, ReplaySubject<Item>> = {};
  // tslint:disable-next-line: variable-name
  protected _item$: Record<ID, Observable<Item>> = {};
  public get item$(): Record<ID, Observable<Item>> { return this._item$; }

  constructor(
    protected override http: HttpClient,
    protected override notificiationService: NotificationService,
    protected override resource: string,
    private deserialize: (data: Item, index: ID) => Item,
    private deserializeSparse: (data: Sparse | Item, index: ID) => Sparse,
  ) {
    super(http, notificiationService, resource);
  }

  // helpers
  public cachedPeek(options: DataServiceIdRequestOptions) {
    const { id } = options;
    return this.item$[id] || this.cachedGet(options);
  }

  // CRUD
  protected cachedGetAll(options: DataServiceRequestOptions = {}) {
    super.requestGetAll<Sparse>(options)
      .pipe(map(items => items.map(this.deserializeSparse))).subscribe(
        (data: Sparse[]) => {
          this.sparseItems = data.reduce((record: Record<ID, Sparse>, item: Sparse) => {
            record[item.id] = item;
            return record;
          }, {});
          this._closeUnusued();
          this.$sparseItems.next(Object.values(this.sparseItems));
        }
      );

    return this.sparseItems$;
  }

  protected cachedGet(options: DataServiceIdRequestOptions) {

    const { id } = options;
    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].pipe(shareReplay(1));

    super.requestGet<Item>(options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        if (this.$item[id]) {
          this.$item[id].next(this._unreference<Sparse, Item>(data));
        }
      });
    return this.item$[id];
  }

  protected cachedCreate(item: Item, options: DataServiceRequestOptions = {}) {
    const subject = new ReplaySubject<Item>(1);
    const observable = subject.asObservable();

    super.requestCreate<Item>(item, options)
      // .pipe(map(this.deserialize)) // only needed if the created object is returned
      .subscribe(() => {
        // This code is different depending on whether the POST returns the complete object created or not:
        // modify to match return format

        // alt 1: backend returns complete object (typically 201 Created or 200 OK)
        // alt 2: backend returns an ID (typically 201 Created)
        // alt 3: no idea? we need to refresh via getAll to get the created item (typically 204 No Content)

        // const id = data.id; // alt 1
        // tslint:disable-next-line: no-commented-code
        // const id = data; // alt 2

        // if alt 1 or 2:
        // this.$item[id] = subject;
        // this.item$[id] = observable;

        // alt 1: we get the created object back:
        // this.$item[id].next(data);

        // alt 2: we only get ID:
        // this.cachedGet(id);

        // alt 3: we get nothing
        // this.cachedGetAll();

        // Ramboll portal specific (201, no data returned):
        this.$item[item.id] = subject;
        this.item$[item.id] = observable;
        subject.next(item);
        this.sparseItems[item.id] = this.deserializeSparse(item, item.id);
        this.$sparseItems.next(Object.values(this.sparseItems));
      });
    return observable;
  }

  protected cachedUpdate(item: Item, options: DataServiceRequestOptions = {}) {

    const id = item.id;
    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].asObservable();
    super.requestUpdate<Item>(item, { id, ...options })
      .pipe()
      .subscribe(() => {
        // backend returns 204 No Content so push the input as the new item
        this.$item[id].next(item);
        if (options.skipRefresh) {
          this.sparseItems[item.id] = this.deserializeSparse(item, item.id);
          this.$sparseItems.next(Object.values(this.sparseItems));
        } else {
          this.cachedGetAll();
        }
      });

    return this.item$[id];
  }

  protected cachedPatch(item: Item, options: DataServiceIdRequestOptions) {

    const { id } = options;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].asObservable();

    super.requestPatch<Item>(item, options)
      .pipe(map(this.deserialize))
      .subscribe((result) => {
        if (result instanceof Location && result.geoLocation) {
          item = Object.assign(item, { geoLocation: result.geoLocation });
        }
        this.$item[id].next(item);
        if (options.skipRefresh) {
          this.sparseItems[item.id] = this.deserializeSparse(item, item.id);
          this.$sparseItems.next(Object.values(this.sparseItems));
        } else {
          this.cachedGetAll();
        }
      });

    return this.item$[id];
  }

  protected cachedDelete(item: Item, options: DataServiceIdRequestOptions) {

    const { id } = options;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].asObservable();

    super.requestDelete<Item>(options)
      .subscribe(() => {
        if (this.$item[id]) {
          this.$item[id].next(item);
          this.$item[id].complete();
          delete this.item$[id];
          delete this.$item[id];
        }
        delete this.sparseItems[id];
        this.$sparseItems.next(Object.values(this.sparseItems));
      });
    return this.item$[id];
  }

  protected _close(id: ID) {
    if (this.$item[id]) {
      this.$item[id].complete();
      delete this.$item[id];
      delete this.item$[id];
    }
  }

  private _closeUnusued() {
    type ItemRecord = Record<ID, ReplaySubject<Item>>;
    this.$item = Object.entries(this.$item).reduce((record: ItemRecord, [id, $data]) => {
      if (this.sparseItems[id]) {
        record[id] = $data;
      } else {
        console.log('id unused, closing', id);
        $data.complete();
      }
      return record;
    }, {});
  }

  private _unreference<From, To>(data: From | To): To {
    try {
      return JSON.parse(JSON.stringify(data)) as To;
    } catch (e) {
      return Object.assign({}, data) as To;
    }
  }

}
