import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.AppInsightsCS,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
      }
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error
    };
    this.appInsights.trackException(exception);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer(envelope => {
      if (envelope) {
        const item = envelope.baseData;
        if (item) {
          item['properties'] = item['properties'] || {};
        }
        if (envelope.tags) {
          envelope.tags['ai.cloud.role'] = environment.project;
          // envelope.tags['ai.cloud.roleInstance'] = ;
        }
      }
    }
    );
  }

  private createRouterSubscription(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.logPageView(undefined, event.urlAfterRedirects);
        }
      });
  }
}
