<div class="container">
    <div class="header">
        <img src="../../../../assets/logo.svg" alt="Logo" width="96" height="20" />
        <h2>{{ 'users.eula.header' | translate }}</h2>
    </div>
    <div class="eula-content" appCustomScroll #appScrollElement="appCustomScroll" #scroll>
        <h3>EULA</h3>
        <p>This End-User License Agreement ("EULA") is a legal agreement between you and Ramboll Sweden AB</p>
        <p>
            This EULA agreement governs your acquisition and use of our MaaS software ("Software") directly from Ramboll
            Sweden AB or indirectly through a Ramboll Sweden AB authorized reseller or distributor (a "Reseller").
            Please read this EULA agreement carefully before completing the installation process and using the MaaSs
            oftware. It provides a license to use the MaaS software and contains warranty information and liability
            disclaimers.
            If you register for a free trial of the MaaS software, this EULA agreement will also govern that trial. By
            clicking "accept" or installing and/or using the MaaS software, you are confirming your acceptance of the
            Software and agreeing to become bound by the terms of this EULA agreement.
            If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent
            that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do
            not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not
            install or use the Software, and you must not accept this EULA agreement.
            This EULA agreement shall apply only to the Software supplied by Ramboll Sweden AB herewith regardless of
            whether other software is referred to or described herein. The terms also apply to any Ramboll Sweden AB
            updates, supplements, Internet-based services, and support services for the Software, unless other terms
            accompany those items on delivery. If so, those terms apply.
        </p>
        <p>
            License Grant
            Ramboll Sweden AB hereby grants you a personal, non-transferable, non-exclusive licence to use the MaaS
            software on your devices in accordance with the terms of this EULA agreement.
            You are permitted to load the MaaS software (for example a PC, laptop, mobile or tablet) under your control.
            You are responsible for ensuring your device meets the minimum requirements of the MaaS software.
        </p>
        <p>
            You are not permitted to:
        </p>
        <ul>
            <li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor
                permit the whole or any part of the Software to be combined with or become incorporated in any other
                software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things
            </li>
            <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
            <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
            <li>Use the Software in any way which breaches any applicable local, national or international law</li>
            <li>use the Software for any purpose that Ramboll Sweden AB considers is a breach of this EULA agreement
            </li>
        </ul>
        <p>
            Intellectual Property and Ownership
            Ramboll Sweden AB shall at all times retain ownership of the Software as originally downloaded by you and
            all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual
            property rights of whatever nature in the Software, including any modifications made thereto) are and shall
            remain the property of Ramboll Sweden AB.
            Ramboll Sweden AB reserves the right to grant licences to use the Software to third parties.
        </p>
        <p>
            Termination
            This EULA agreement is effective from the date you first use the Software and shall continue until
            terminated. You may terminate it at any time upon written notice to Ramboll Sweden AB.
            It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such
            termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop
            all access and use of the Software. The provisions that by their nature continue and survive will survive
            any termination of this EULA agreement.

            Governing Law
            This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be
            governed by and construed in accordance with the laws of Sweden.
        </p>
        <br>
        <h3>Privacy Policy and Cookie Policy</h3>
        <p>Introduction<br>
            This Privacy Policy and Cookie Policy outline
            how we collect, use, and protect information obtained through your use of our system.</p>
        <p>Information We Collect</p>
        <ul>
            <li>Personal Information: When you register or log in, we collect personal information such as your name,
                email address, and other details required for authentication.</li>
            <li>Cookies: We use cookies to enhance your user experience and for essential functions such as keeping you
                logged in. By using our system, you consent to the use of cookies.</li>
        </ul>
        <p>How We Use Your Information</p>
        <ul>
            <li>Authentication: To verify your identity when you log in and to maintain your logged-in state.</li>
            <li>Remember Me: If you choose the "Remember Me" option, we store a persistent cookie to keep you logged in
                across sessions.</li>
            <li>User Activity Tracking: We may track your activities within the system to provide personalized content
                and improve our services.</li>
        </ul>
        <p>Information Sharing<br>
            We do not sell, trade, or otherwise transfer your personal information to outside parties.
            However, we may share information with trusted third parties who assist us in operating our system.</p>
        <p>Data Security<br>
            We implement a variety of security measures to protect the safety of your personal information.</p>
        <p>Your Choices</p>
        <ul>
            <li>You can manage your cookie preferences through your browser settings. Please note that disabling cookies
                may affect the functionality of our system.</li>
            <li>You have the right to access and correct your personal information. Contact us at <a
                    href="mailto: info@maas.ramboll.cloud">info@maas.ramboll.cloud</a> for assistance.</li>
        </ul>
        <p>Changes to This Policy<br>
            We may update this Privacy Policy and Cookie Policy periodically. Your continued use of the site after any changes indicates your acceptance of the
            revised terms.
        </p>
        <p>Contact Us<br>
            If you have any questions or concerns regarding this Privacy Policy and Cookie Policy, please
            contact us at <a href="mailto: info@maas.ramboll.cloud">info@maas.ramboll.cloud</a>
        </p>
        <p>Ramboll Sweden AB © 2023</p>
    </div>
    <div class="footer">
        <button mat-stroked-button (click)="confirmDeclineEula()">{{ 'users.eula.decline' | translate }}</button>
        <button mat-flat-button color="primary" class="accept-btn" [disabled]="appScrollElement.disableBtn"
            (click)="acceptEula()">
            {{ 'users.eula.accept' | translate}}
        </button>
    </div>
</div>