import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'
import { EulaGuard } from './core/guards/eula.guard'
import { Error404PageComponent } from './shared/pages/error404-page/error404-page.component'
import { LoginPageComponent } from './shared/pages/login-page/login-page.component'

const routes: Routes = [
  {
    path: 'locations',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./locations/locations.module').then((m) => m.LocationsModule),
    data: {
      title: 'Locations',
      parent: 'locations',
      subEntity: 'Locations'
    },
  },
  {
    path: 'blastings',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./locations/locations.module').then((m) => m.LocationsModule),
    data: {
      title: 'Blastings',
      parent: 'blastings',
      subEntity: 'Blastings'
    },
  },
  {
    path: 'observation-points',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./locations/locations.module').then((m) => m.LocationsModule),
    data: {
      title: 'Observation points',
      parent: 'observation_points',
      subEntity: 'Observation points'
    },
  },
  {
    path: 'blastmanager',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./initiatives/initiatives.module').then((m) => m.InitiativesModule),
    data: {
      title: 'BlastManager',
    },
  },
  {
    path: 'initiatives',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./initiatives/initiatives.module').then((m) => m.InitiativesModule),
    data: {
      title: 'Initiatives',
    },
  },
  {
    path: 'devices',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./devices/devices.module').then((m) => m.DevicesModule),
    data: {
      title: 'Devices',
    },
  },
  {
    path: 'companies',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./companies/companies.module').then((m) => m.CompaniesModule),
    data: {
      title: 'Companies',
    },
  },
  {
    path: 'users',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    data: {
      title: 'Users',
    },
  },
  {
    path: 'projects',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    data: {
      title: 'Projects',
    },
  },
  {
    path: 'work-areas',
    canActivate: [MsalGuard, EulaGuard],
    loadChildren: () => import('./work-areas/work-areas.module').then((m) => m.WorkAreasModule),
    data: {
      title: 'Work Areas',
    },
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginPageComponent,
    data: { title: 'Login' },
    children: [],
  },
  {
    path: 'login/eula',
    pathMatch: 'full',
    component: LoginPageComponent,
    data: { title: 'Login' },
    children: [],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  { path: '404', component: Error404PageComponent, data: { title: 'Oops!' } },
  { path: '**', redirectTo: '/404' },
]

const isIframe = window !== window.parent && !window.opener

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      initialNavigation: isIframe ? 'disabled' : 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
