import { Component, OnInit, inject } from '@angular/core';
import { combineLatest, map, mergeMap, of, take } from 'rxjs';
import { ID } from 'src/app/core/interfaces/model.interface';
import { UsersService } from 'src/app/users/services/users.service';
import { UserService } from '../../services/user.service';
import { CompaniesV2Service } from 'src/app/companies/services/companies.v2.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  private companiesV2Service = inject(CompaniesV2Service)

  userId!: string | ID

  links$ = this.msalUserService.isClient$.pipe(map(isClient => [
    {
      label: 'menu.item_dashboard',
      link: 'dashboard',
      icon: 'dashboard',
      disabled: true,
      hidden: isClient
    },
    {
      label: 'menu.item_work_areas',
      link: 'work-areas',
      icon: 'map',
      disabled: false,
      hidden: isClient
    },
    {
      label: 'menu.item_Locations',
      link: 'locations',
      icon: 'place',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_blastings',
      link: 'blastings',
      icon: 'brightness_high',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_observation_points',
      link: 'observation-points',
      icon: 'track_changes',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_initiatives',
      link: 'initiatives',
      icon: 'group_work',
      disabled: false,
      hidden: isClient
    },
    {
      label: 'menu.item_projects',
      link: 'projects',
      icon: 'snippet_folder',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_companies',
      link: 'companies',
      icon: 'business',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_users',
      link: 'users',
      icon: 'person',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_devices',
      link: 'devices',
      icon: 'thermostat',
      disabled: false,
      hidden: isClient
    },
  ]))

  public bottomLinks = [
    {
      label: 'menu.item_profile',
      link: '',
      icon: 'person_pin',
      disabled: false,
      hidden: false
    },
    {
      label: 'menu.item_logout',
      icon: 'logout',
      disabled: false,
      hidden: false
    },
  ]

  isClient = false

  constructor(private msalUserService: UserService, private appUserService: UsersService) { }

  ngOnInit(): void {
    this.msalUserService.currentUser$.pipe(take(1), mergeMap(user => {
      return combineLatest([of(user), this.companiesV2Service.search({ params: { companyId: user.companyId as string } }).pipe(map(res => res.data))])
    })).subscribe(([user, company]) => {
      if (user) {
        this.msalUserService.setIsClient(company?.[0]?.companyType === 'client')
        this.bottomLinks[0].link = 'users/' + user.userId + '/settings'
      }
    })
  }

  logout(): void {
    this.msalUserService.logout()
  }
}
