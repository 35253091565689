<div class="common-dialog" [style.max-width]="data.styles?.maxWidth ?? 'unset'">
  <div mat-dialog-title class="common-dialog-title">{{data.title | translate}}</div>
  <div mat-dialog-content class="common-dialog-message">
    {{data.message | translate}}
    <ul *ngIf="data.items">
      <li *ngFor="let item of data.items; trackBy: trackByString">{{item}}</li>
    </ul>
  </div>
  <div *ngIf="data.form" [formGroup]="data.form.formGroup" class="common-dialog-forms">
    <ng-container *ngFor="let item of data.form.formItems">
      <ng-container *ngIf="item.type === 'checkbox'">
        <mat-checkbox [color]="item.color ?? 'primary'" [formControlName]="item.formControlName">{{ item.text }}</mat-checkbox>
      </ng-container>
      <ng-container *ngIf="item.type === 'datepicker'">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" [formControlName]="item.formControlName" [placeholder]="'tasks.dialog.choose_date' | translate">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions class="common-dialog-buttons">
    <ng-container *ngFor="let button of data.buttons; trackBy: trackByButton">
      <ng-container [ngSwitch]="button.type">
        <button *ngSwitchDefault mat-button [color]="button.color ?? 'primary'" (click)="onClick(button.value)">
          {{button.text | translate}}
        </button>
        <button *ngSwitchCase="'stroked'" mat-stroked-button [color]="button.color ?? 'primary'"
          (click)="onClick(button.value)">
          {{button.text | translate}}
        </button>
        <button *ngSwitchCase="'flat'" mat-flat-button [color]="button.color ?? 'primary'"
          (click)="onClick(button.value)">
          {{button.text | translate}}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>