import { Device } from "@ramboll/rsedt-shared";

/**
 * Describes object types for objects at a location
 * Eg. Borehole, Real Estate, Building, Casing, Cabinet
 * Very generic object descriptions of two categories.
 * LOCATION_TYPING - These object form the main object of the location.
 * STRUCTURAL - These are objects that are related to the LOCATION_TYPING objects.
 */
export interface LocationObjectType {
    typeId: string;
    name: string;
    category: 'LOCATION_TYPING' | 'STRUCTURAL';
}

/**
 * Describes the specifc object at a location
 * This can be one or more BOREHOLEs or a REAL ESTATE with one or more BUILDINGS.
 *
 * Example:
 * The BOREHOLE (1) is a LOCATION_TYPING object which may have a CASING (A) and CABINET (B) associated with it.
 * The CASING is relevant to the groundwater measurement as it has a measured altitude/z-coordinate
 * that allows for the groundwater level to be calculating the water level from the BOREHOLE throught the CASING.
 * The water level can be measured by a device that is mounted in the CABINET.
 *
 * BOREHOLE (1)     [LOCATION_TYPING]
 *      CASING(A)   [STRUCTURAL]
 *      CABINET(B)  [STRUCTURAL]
 *
 * A REAL ESTATE (2) has a main BUILDING (A) and garage BUILDING (B) with a metal CABINET (C) for electricy measurement
 *
 * REAL ESTATE (2)      [LOCATION_TYPING]
 *      BUILDING (A)    [STRUCTURAL]
 *      BUILDING (B)    [STRUCTURAL]
 *          CABINET (C) [STRUCTURAL]
 *
 * So the LOCATION_TYPING objects are the ones with a parent Location relation and the STRUCTURAL objects have another location object as a parent.
 */
// export interface AbstractLocationObject {
//     objectId: string;
//     name: string;
//     notes: string;
//     objectType: LocationObjectType;
//     parentId?: string;
//     parent?: Location | AbstractLocationObject;
//     objects?: AbstractLocationObject[];
//     metadata: Record<string, string>;

//     /**
//      * IoT devices at the structural location.
//      *  TODO: separate the location object types and have devices only on structural?
//      */
//     deviceIds?: string[];
//     devices?: Device[];

//     createdAt?: Date;
//     updatedAt?: Date;
// }

export class AbstractLocationObject {
  public objectId: string;
  public name: string;
  public notes: string;
  public objectType: LocationObjectType;
  public parentId?: string;
  public parent?: Location | AbstractLocationObject;
  public objects?: AbstractLocationObject[];
  public metadata: Record<string, string>;
  public deviceIds?: string[];
  public devices?: Device[];
  public createdAt?: Date;
  public updatedAt?: Date;

  constructor(input: AbstractLocationObject) {
    this.objectId = input.objectId;
    this.name = input.name;
    this.notes = input.notes;
    this.objectType = input.objectType;
    this.parentId = input.parentId;
    this.parent = input.parent;
    this.objects = input.objects;
    this.metadata = input.metadata;
    this.deviceIds = input.deviceIds;
    this.devices = input.devices;
    this.updatedAt = input.updatedAt;
    this.deviceIds = input.deviceIds;
  }
}
