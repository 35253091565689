import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalCustomNavigationClient } from '@azure/msal-angular';
import { UserService } from './shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DeepLinkService } from './core/services/deep-link.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'rsedt-mgmt-portal';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService,
    public userService: UserService,
    public router: Router,
    private deepLinkService: DeepLinkService,
    private location: Location,
    public msalUserService: UserService,
    private translate: TranslateService
  ) {
    const customNavigationClient = new MsalCustomNavigationClient(this.authService, this.router, this.location);
    this.authService.instance.setNavigationClient(customNavigationClient);
    translate.setDefaultLang('en');
    //if norwegian language in browser settings, use it
    if (['no', 'nb', 'nn'].includes(navigator.language.substring(0, 2))) {
      this.translate.use('no');
    }
  }

  ngOnInit(): void {
    if (!this.msalUserService.getAccountInfo()) {
      // Make a record of original url to support deep linking
      this.deepLinkService.setDeepLink(window.location.pathname + window.location.search);
      // Then redirect to login
      this.router.navigateByUrl('/login')
    }
  }
}
