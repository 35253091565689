import { ID, Model } from 'src/app/core/interfaces/model.interface';
import { AbstractLocationObject } from '../types/location_object';

export class LocationObject extends AbstractLocationObject implements Model {
  id!: ID;
  override objects: LocationObject[];
  deserialize(input: AbstractLocationObject, id?: ID): this {
    return Object.assign(
      this,
      input,
      id ? { id } : {},
      { objects: input.objects ? input.objects.map(obj => new LocationObject(obj).deserialize(obj)) : [] }
    );
  }

  constructor(input: AbstractLocationObject) {
    super(input);
    this.objects = input.objects as LocationObject[]
  }
}
