import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-confirm-decline-eula',
  templateUrl: './confirm-decline-eula.component.html',
  styleUrls: ['./confirm-decline-eula.component.scss']
})
export class ConfirmDeclineEulaComponent {

  constructor(
    private msalUserService: UserService,
  ) { }

  declineEula() {
    this.msalUserService.logout()
  }
}
